import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';
import FormModal from '../components/formmodal';
import Valuation from '../components/forms/valuation';
export default class bostadguide extends Component {
    constructor(props) {
        super(props);
        this.handleToggle = React.createRef();
      }
    buttonClick () {
        this.handleToggle.current.modalToggle()
    }
    componentDidMount() {
        document.title = 'Bostadsguide';
      }
    render() {
        return(
            <div>
                <FormModal ref={this.handleToggle}>
                    <Valuation></Valuation>
                </FormModal>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/bostadsratt.mp4" brightness="brightness(60%)"  buttonFunc={this.buttonClick.bind(this)} buttontext="BOKA FRI VÄRDERING" header="Säljguide bostadsrätt"  ></Fullvid>

                
                
                <Contentblock>
                <div id="content1"></div>

                    <Textsection alignment="text-start fs-4" header="Första mötet med vårt team
" paragraph="När ni funderar på att sälja er bostad bokar vi in ett möte hos er eller på vårt kontor. Vi går igenom det bästa upplägget för er bostadsförsäljning. Vi värderar bostaden och förklarar också i detalj hur försäljningsprocessen går till."></Textsection>
                
                <Textsection alignment="text-start fs-4" header="Styling" 
                paragraph="Inför en visning är det viktigt att bostaden är i ordning. Det kan vara så enkelt som att man städat, plockat undan och gjort det lite extra fint. Vi lämnar alltid tips på vad som kan vara bra att tänka på när vi är på plats. För den som önskar så finns det möjlighet att få hjälp med homestyling. Det är många gånger de små detaljerna som gör den stora skillnaden. A och O är att skapa en röd tråd i hemmet och att skapa rymd. Detta för att göra ett så lugnt intryck som möjligt och lämna utrymme åt de potentiella köparna, så att de kan känna igen sig själva i den nya bostaden. Läs mer om homestyling här."></Textsection>
                <Textsection alignment="text-start fs-4" header="Fotograf, filmare & grafiker" 
                paragraph="Presentationen av er bostad är viktig. Den måste hålla hög kvalité så att vi kan visa upp bostaden på bästa sätt. Därför har vi en proffsfotograf som kommer ut och fotograferar er bostad. Saknas det ritningar så mäter fotografen även upp bostaden och tillverkar ritningar. Dessa är viktiga så att kunden i lugn och ro kan titta på dessa i hemmets lugna vrå när man funderar på ett eventuellt köp. Dessa bilder använder vi sedan i marknadsföringen. Läs mer om proffsfotograf här."></Textsection>
                <Textsection alignment="text-start fs-4" header="Broschyrmaterial digitalt & fysiskt" 
                paragraph="Efter att vi har gått igenom bostaden och fotograferat den så är det dags för en bostadspresentation. Ansvarig fastighetsmäklare beskriver er bostad och en presentation börjar ta form med de bilder som fotografen tagit. Självklart godkänner ni texterna innan försäljningen påbörjas. När texterna är färdiga gör vi en broschyr över er bostad. Denna finns på vår hemsida samt delas ut till spekulanter på visningen."></Textsection>
                <Textsection alignment="text-start fs-4" header="Marknadsföring" 
                paragraph="Vi älskar marknadsföring! Hos oss får ni den bästa marknadsföringen på web och i sociala medier. Vi annonserar på Hemnet där vi har årsbokningar på Mäklartipset vilket kan lyfta upp bostaden överst i söklistan om den har åkt ner. Annars är Sociala medier något som ligger oss varmt om hjärtat då vi jobbat med detta i drygt 10 år vilket är länge i sammanhanget. Vi har drygt 6000 följare på vår Facebooksida vilket säkerställer att vi får en bra spridning av annonserna då vi till exempel vill att dessa ska synas i Göteborgsområdet. Som tilläggstjänst kan vi även erbjuda att din bostad exponeras med film och statister."></Textsection>
                <Textsection alignment="text-start fs-4" header="Visning" 
                paragraph="Hos oss på Gbg Fast har vi alltid personliga och bokade visningar. Detta gör att kunden i lugn och ro kan gå igenom bostaden i den takt kunden önskar samt ställa frågor till mäklaren. Ofta är vi både mäklare och assistent på visningar för att kunna bistå kunderna och visa bostaden på bästa sätt. Vi värnar om er integritet, därav de bokade visningarna med namn och telefonnummer. Ni har på så sätt möjlighet att få reda på vilka som besökt er bostad."></Textsection>
                <Textsection alignment="text-start fs-4" header="Budgivning" 
                paragraph="En budgivning kan uppkomma när flera spekulanter är intresserade. Vi använder oss av öppen budgivning, den vanligaste varianten. Det innebär att spekulanter lämnar sitt bud till ansvarig fastighetsmäklare, som löpande redovisar det högsta budet till säljaren och alla budgivare. Efter avslutad budgivning lämnas budgivningslistan med namn, telefonnummer, bud och tidpunkt till köpare och säljare.
                Säljaren har fri prövningsrätt och behöver inte sälja till den som lagt det högsta budet. Innan överlåtelseavtal är undertecknat av båda parter har inget bindande avtal träffats. Om någon spekulant lämnar ett bud efter avslutad budgivning men innan överlåtelseavtal undertecknats, är mäklaren skyldig att framföra detta bud till säljaren, som tar ställning till om budet ska beaktas."></Textsection>
<Textsection alignment="text-start fs-4" header="Överlåtelseavtal & medlemskapsansökan" 
                paragraph="Då köpare och säljare är överens om pris och tillträdesdatum träffas säljare, köpare och mäklare på mäklarens kontor. Detta är oftast första gången som säljare och köpare träffas och ett fint tillfälle för köpare att ställa frågor till säljaren om bostaden och dess omgivningar. I anslutning till att man undertecknar överlåtelseavtalet så fyller köparen också i en medlemsansökan om medlemskap i bostadsrättsföreningen. Det är en förutsättning för köpet att köparen blir godkänd som medlem. Det ska tilläggas att det är mycket ovanligt att en köpare inte blir godkänd. Mäklaren ansöker om medlemskap för köparen och kontaktar köparens bank för bokning av tid för tillträdet framöver."></Textsection>
                <Textsection alignment="text-start fs-4" header="Tillträde och överlämnande" 
                paragraph="Överlåtelse av bostadsrätten sker enligt överenskommelse. Vi bokar in tid och plats med köpare, säljare och bank. Vid tillträdet överlämnar fastighetsmäklaren en likvidavräkning, vilket är en ekonomisk redovisning mellan säljare och köpare, samt ett köpebevis, som är ett slutgiltigt kvitto på köpet. Säljaren lämnar ifrån sig nycklar och andra handlingar av vikt till köparen.
                Säljaren har fri prövningsrätt och behöver inte sälja till den som lagt det högsta budet. Innan överlåtelseavtal är undertecknat av båda parter har inget bindande avtal träffats. Om någon spekulant lämnar ett bud efter avslutad budgivning men innan överlåtelseavtal undertecknats, är mäklaren skyldig att framföra detta bud till säljaren, som tar ställning till om budet ska beaktas."></Textsection>
                <Textsection alignment="text-start fs-4" header="Skatteberäkning" 
                paragraph="Efter avslutad affär erbjuder deklarationshjälp och upprättar deklarationsbilaga K6 vilket avser försäljning av bostadsrätt."></Textsection>
                
                <h2 className="text-center">Välkommen till Gbg Fast!</h2>
                </Contentblock>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>
            </div>
        )
    }
}