import React, { Component } from 'react'

export default class Contentblock extends Component {

    render() {
        

        return(

        <div className="content-block" >
            <div className="content-block-content2" style={{backgroundColor:this.props.bg}}>
            {this.props.children}
            </div>

          </div>

        )
    }
}

Contentblock.defaultProps = {
    bg:"none",
}