import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';
import FormModal from '../components/formmodal';
import Valuation from '../components/forms/valuation';
export default class villaguide extends Component {
    constructor(props) {
        super(props);
        this.handleToggle = React.createRef();
      }
    buttonClick () {
        this.handleToggle.current.modalToggle()
    }
    render() {
        return(
            <div>
                <FormModal ref={this.handleToggle}>
                    <Valuation></Valuation>
                </FormModal>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/villaguide.mp4" brightness="brightness(60%)" buttonFunc={this.buttonClick.bind(this)} buttontext="BOKA FRI VÄRDERING" header="Säljguide villa"  ></Fullvid>


                
                <Contentblock>
                <div id="content1"></div>

                    <Textsection alignment="text-start fs-4" header="Första mötet med vårt team
" paragraph="När ni funderar på att sälja er bostad bokar vi in ett möte hos er eller på vårt kontor. Vi går igenom det bästa upplägget för er bostadsförsäljning. Vi värderar bostaden och förklarar också i detalj hur försäljningsprocessen går till."></Textsection>
                
                <Textsection alignment="text-start fs-4" header="Styling" 
                paragraph="Inför en visning är det viktigt att bostaden är i ordning. Det kan vara så enkelt som att man städat, plockat undan och gjort det lite extra fint. Vi lämnar alltid tips på vad som kan vara bra att tänka på när vi är på plats. För den som önskar så finns det möjlighet att få hjälp med homestyling. Det är många gånger de små detaljerna som gör den stora skillnaden. A och O är att skapa en röd tråd i hemmet och att skapa rymd. Detta för att göra ett så lugnt intryck som möjligt och lämna utrymme åt de potentiella köparna, så att de kan känna igen sig själva i den nya bostaden. Läs mer om homestyling här."></Textsection>
                <Textsection alignment="text-start fs-4" header="Fotograf, filmare & grafiker" 
                paragraph="Presentationen av er bostad är viktig. Den måste hålla hög kvalité så att vi kan visa upp bostaden på bästa sätt. Därför har vi en proffsfotograf som kommer ut och fotograferar er bostad. Saknas det ritningar så mäter fotografen även upp bostaden och tillverkar ritningar. Dessa är viktiga så att kunden i lugn och ro kan titta på dessa i hemmets lugna vrå när man funderar på ett eventuellt köp. Dessa bilder använder vi sedan i marknadsföringen. Läs mer om proffsfotograf här."></Textsection>
                <Textsection alignment="text-start fs-4" header="Broschyrmaterial digitalt & fysiskt" 
                paragraph="Efter att vi har gått igenom bostaden och fotograferat den så är det dags för en bostadspresentation. Ansvarig fastighetsmäklare beskriver er bostad och en presentation börjar ta form med de bilder som fotografen tagit. Självklart godkänner ni texterna innan försäljningen påbörjas. När texterna är färdiga gör vi en broschyr över er bostad. Denna finns på vår hemsida samt delas ut till spekulanter på visningen."></Textsection>
                <Textsection alignment="text-start fs-4" header="Marknadsföring" 
                paragraph="Ni får den maximala marknadsföringen när er bostad finns på vår hemsida, Hemnet.se. och  i sociala medier hos oss. Vi har marknadsfört bostäder under olika varumärken med en  samlad erfarenhet på 70 år så just detta kan vi. Som tilläggstjänst kan vi erbjuda att din  bostad exponeras med film och statister. "></Textsection>
                <Textsection alignment="text-start fs-4" header="Visning" 
                paragraph="Hos oss är det alltid personliga och bokade visningar. Detta gör att kunden i lugn och ro  kan gå igenom bostaden i den takt kunden önskar samt ställa frågor till mäklaren. Vi  värnar om er integritet, därav de bokade visningarna med namn och telefonnummer. Ni  har på så sätt möjlighet att få reda på vilka som besökt er bostad. "></Textsection>
                <Textsection alignment="text-start fs-4" header="Budgivning" 
                paragraph="En budgivning kan uppkomma när flera spekulanter är intresserade. Vi använder oss av öppen budgivning, den vanligaste varianten. Det innebär att spekulanter lämnar sitt bud till ansvarig fastighetsmäklare, som löpande redovisar det högsta budet till säljaren och alla budgivare. Efter avslutad budgivning lämnas budgivningslistan med namn, telefonnummer, bud och tidpunkt till köpare och säljare.
                Säljaren har fri prövningsrätt och behöver inte sälja till den som lagt det högsta budet. Innan överlåtelseavtal är undertecknat av båda parter har inget bindande avtal träffats. Om någon spekulant lämnar ett bud efter avslutad budgivning men innan överlåtelseavtal undertecknats, är mäklaren skyldig att framföra detta bud till säljaren, som tar ställning till om budet ska beaktas."></Textsection>
<Textsection alignment="text-start fs-4" header="Kontrakt" 
                paragraph="Då kontraktsmötet äger rum går fastighetsmäklaren igenom kontraktet och stämmer av om  det råder några oklarheter. Vid detta tillfälle har även säljaren möjlighet att teckna en  dolda fel-försäkring."></Textsection>
                <Textsection alignment="text-start fs-4" header="Tillträde" 
                paragraph="Överlåtelse av fastigheten sker enligt överenskommelse. Vi bokar in tid när köpare, säljare  och bank ska träffas. Mötet sker på vårt kontor och vi använder Tambur vilket är en tjänst  framtagen av UC där banken på ett kvalitetssäkrat vis är med på distans när vi gör upp  affären. Vid tillträdet överlämnar fastighetsmäklaren en likvidavräkning, vilket är en  ekonomisk redovisning mellan säljare och köpare, samt ett köpebrev, som är ett slutgiltigt  kvitto på köpet. Säljaren lämnar ifrån sig nycklar och andra handlingar av vikt till köparen. "></Textsection>
                <Textsection alignment="text-start fs-4" header="Skatteberäkning" 
                paragraph="Efter avslutad affär erbjuder deklarationshjälp och upprättar deklarationsbilaga K5 vilket  avser försäljning av villa. 
                "></Textsection>
                
                <h2 className="text-center">Välkommen till Gbg Fast!</h2>
                </Contentblock>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>
            </div>
        )
    }
}