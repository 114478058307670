
import React, { Component } from 'react';
export default class SmoothText extends Component {
    constructor(props) { 
        super(props); 
        this.state = {
            
        }; 
        this.updateText = this.updateText.bind(this)
    }
    wordList = this.props.wordList
    curIndex = 0 
    updateText() {
        console.log("test")
        this.setState({currentText:this.wordList[this.curIndex]})
        this.curIndex +=1

        if(this.curIndex >= this.wordList.length) {
            this.curIndex = 0
        }
        
        
    }
    componentDidMount() {
        console.log("test")
        this.updateText()
        this.interval = setInterval(() => this.updateText(), this.props.speed ? this.props.speed: 1500);

    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
    return(
        <div>
        {this.state.currentText && 
        
        <div className="smoothText">
            <h2 style={{fontSize:this.props.fontSize, marginBottom:'0'}}>{this.state.currentText}</h2>  
            {this.props.fontSize == "4rem" && <h6 style={{fontWeight:'500'}}>med GBGFAST</h6>}
            {this.props.fontSize == "1.5rem" && <h6 style={{fontWeight:'500',fontSize:this.props.fontSize,}}>GBGFAST x Morningstar Media</h6>}
        </div>
        }
        </div>
    )}

}