import React, { Component } from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export default class Tabbar extends Component {
  getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  getValue(data = "", title = "", suffix = "", prefix = "") {
    data = this.getNestedObject(this.props.data, data);

    if (data) {
      return (
        <li><p className="fs-5 mb-1" style={{display:"inline-block"}}>
          <span className="fw-bolder m-0">
            {title ? title + ":" : null}{" "}
          </span>
          {prefix + data.toLocaleString() + " " + suffix}
          
        </p></li>
      );
    }
  }
  render() {
    return (
      <div className="text-start">
        <Tabs defaultActiveKey="3">
          <TabPane
            tabBarStyle={{ color: "orange !important" }}
            tab="General"
            key="1"
          > 
          <ul className="p-0 mt-2">
          {this.getValue(["type"], "Type", "")}
          {this.getValue(["town"], "Town", "")}

            {this.getValue(["pool"], "Pools", "")}
            {this.getValue(["beds"], "Bedrooms", "")}

            {this.getValue(["baths"], "Bathrooms", "")}
            
            </ul>
          </TabPane>


          <TabPane
            tabBarStyle={{ color: "orange !important" }}
            tab="Features"
            key="2"
          >
            <ul className="p-0 mt-2">
            {this.props.data["features"] && this.props.data["features"]["feature"].map((key,i) => {
              return <li key={i} style={{textTransform:"capitalize"}}>{key}</li>
            })}</ul>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
