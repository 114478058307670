import React, { Component} from 'react'
import { Select } from 'antd';
import Slide from 'react-reveal/Slide';

import Objektspain from './objektspain';
import Textsection from './textsection';
const { Option } = Select;






export default class Shopspain extends Component {


  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
      filtered:false,
      priceFiltered:false,
      objectCount:25,
      filterList:[],
    }; 
    this.setRange = this.setRange.bind(this)
    this.setFilter = this.setFilter.bind(this)
} 

  async getData() {
    var res = await fetch("/premiumvilla.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }





setSearchQuery(query, data) {
  var searchQuery = query
  var results = []

  this.state.deals.property.map((key, index) => {
    try {
      if(key["town"].toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push(key)
      }
    } 
    catch(exception_var) {
      console.log("No streetadress defined")
    }
    try {
      if(key["province"].toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push(key)
      }
    } 
    catch(exception_var) {
      console.log("No district defined")
    }
    try {
      if(key["price"].toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push(key)
      }
    } 
    catch(exception_var) {
      console.log("No city defined")
    }

  })
   


  console.log(results)
  this.setState({filtered:true, filterList:results})

  

  
}



setRange(value) {
  var min = parseInt(value.split(",")[0])
  var max = parseInt(value.split(",")[1])

  this.setState({priceMin: min, priceMax:max, priceFiltered:true})
}
setFilter(value) {
  
  var results = []

  this.state.deals.property.map((key, index) => {
    if(value == "hasVideo") {
      if(key["video_url"]) {
        results.push(key)
      }
    }
    else if (value == "hasFeatures") {
      if(key["features"]) {
        results.push(key)
      }
    }

  })
   


  this.setState({filtered:true, filterList:results})

}



componentDidMount() {
  this.getData();
}


    render() {
        
      
        return(
            <div className="mt-4">
              <div>
 
              </div>
                <div className="container-sm filterW">
                    <div className="row  justify-content-center text-center ">
                    <div className="col p-0  px-1">
                                <input
                        value={this.state.searchQuery}
                        onInput={e => this.setSearchQuery(e.target.value, this.state.deals)}
                        type="text"
                        placeholder="Sök"
                        name="s"
                        id="searchbar"
                        style={{width:"100%"}}
                    />
                    
                    </div>
                    
                    <div className="col px-1">
                        <Select defaultValue="Prisklass" style={{ width: "100%" }} onChange={this.setRange}>


                          
                            <Option value="0,499999">&#60; 500 000 EUR</Option>
                            <Option value="500000,1000000">500 000 - 1 000 000 EUR</Option>
                            <Option value="1000000,2000000">1 000 000 - 2 000 000 EUR</Option>
                            <Option value="2000000,4999999">2 000 000 - 5 000 000 EUR</Option>
                            <Option value="5000000,10000000000000"> &#62; 5 000 000 EUR</Option>
                            





                        </Select>
                        
                    </div>
                    <div className="col px-1">
                        <Select defaultValue="Filter" style={{ width: "100%" }} onChange={this.setFilter}>



                            <Option value="hasFeatures">Features described</Option>
                            <Option value="hasVideo">Has Video</Option>
         
                            





                        </Select>
                        
                    </div>
                    
                    </div>


          </div>
  

            <div className="fastighetgrid">
            
            {
              this.state.deals && 
              
             this.state.deals.property.filter(key => ((this.state.filterList.includes(key) || !this.state.filtered) && ((key["price"] > this.state.priceMin && key["price"] < this.state.priceMax) || !this.state.priceFiltered ))).map((key,i) => {
               
                if(i <= this.state.objectCount) {
                  return <Objektspain key={i} data={key}></Objektspain>

                }
                

               
               
              

              
              
            })
               }



               
          </div>
          <div className='text-center m-5'>
          <input type="submit" onClick={() => this.setState({objectCount:this.state.objectCount+25}) } value="Load more.." className="btn mt-5" style={{height: "40px", padding: "0",}} />

          </div>


          </div>
        )
    }
}




