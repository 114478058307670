import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Footer from '../components/footer';
import Textsection from '../components/textsection';
export default class miljo extends Component {
    componentDidMount() {
        document.title = 'Miljö';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid arrow={false} target={{hash:"#content1"}} height="80vh" img="/vids/miljo.mp4" header="Den klimatsmarta mäklaren" buttontext="SE VAD VI GÖR FÖR KLIMATET"></Fullvid>
                <div id="content1">

                <Contentblock>
                    <Textsection header="En grönare värld" paragraph="Gbgfast arbetar med ambitionen att bedriva en verksamhet som tar hänsyn till det samhällsansvar som innefattar företagande inom svenskt näringsliv. Därför verkar vi för ett långsiktigt hållbart arbete genom att ta ett socialt, miljömässigt såväl som ett ekonomiskt ansvar. Vi lägger stor fokus på att alltid leverera klimatsmarta tjänster, där varje enskild del av vårt arbete är i enlighet med verksamhetens samhällsansvar såväl som gällande lagstiftning.">

 
                    </Textsection>

                </Contentblock>
                <Contentblock>
                    <Textsection header="Vårt miljöarbete" paragraph="">
                    <div className="envCont">
                    
                    <ul>
                        <li>Frekvent pappersåtervinning</li>
                        <li> Vi skriver inte ut papper i överflöd och minskar därmed onödig konsumtion och förbrukning av arbetsmaterial</li>
                        <li>Vi källsorterar all diverse kontorsavfall</li>
                        <li>Vid bokandet av tjänsteresor letar vi efter det mest miljövänliga alternativet</li>
                        <li> Vi arbetar med digitala bostadsbeskrivningar och skriver enbart ut beskrivningar i undantagsfall på begäran</li>
                        <li>Vi delger flexibla arbetstider till våra medarbetare</li>
                        <li>Vi källsorterar vårt matavfall</li>
                        <li>Vi uppmuntrar våra medarbetare att exempelvis åka kollektivt, gå eller cykla till arbetet om andra färdmedel inte anses som ytterst nödvändiga </li>
                    </ul>
                    </div>
 
                    </Textsection>

                </Contentblock>
                
                </div>


                <div className="container text-center">
                    <img className="grid_bild" style={{boxShadow:"none"}} src="./img/miljologa.png" alt=""/>

                </div>


                    

                <Contentblock>
                    <Footer></Footer>
                </Contentblock>

            </div>
        )
    }
}