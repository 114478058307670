import React, { Component } from 'react'
import {Link} from "react-router-dom";

export default class Footer extends Component {

    render() {
        

        return(
            <footer className="text-center text-lg-start  pt-5 text-white"  style={{width:"100vw",background: "rgba(72,68,68,1)"}}>

            
                <div className="container-xl text-center text-md-start ">
                  <div className="row mt-3">
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase  mb-4 text-white">
                        Sälja
                      </h6>
                      <p>
                      <Link to="/sell">Sälj Med Oss</Link>
                      </p>
                      <p>
                      <Link to="/sell">Boka Värdering</Link>
                      </p>

                      <p>
                      <Link to="/spanien">Spanien</Link>
                      </p>
                      <p>
                      <Link to="/#underhand">Underhand</Link>
                      </p>

                      <p>
                      <Link to="/villaguide">Säljguide Villa</Link>
                      </p>
                      <p>
                      <Link to="/bostadguide">Säljguide Bostadsrätt</Link>
                      </p>
                    </div>
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase  mb-4 text-white">
                        Köpa
                      </h6>
                      <p>
                      <Link to="/listings">Till Salu</Link>
                      </p>

                      <p>
                      <Link to="/team">Kontakta Mäklare</Link>
                      </p>

                      <p>
                      <Link to="/spanien">Spanien</Link>
                      </p>



                      <p>
                        <Link to="/kopguidebostadsratt">Köpguide Bostadsrätt</Link>
                      </p>
                      <p>
                      <Link to="/kopguidevilla">Köpguide Villa</Link>
                      </p>
                    </div>
                    

                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase  mb-4 text-white">
                        Annat
                      </h6>
                      
                      <p>
                      <Link to="/nyproduktion">Nyproduktion</Link>
                      </p>
                      <p>
                      <Link to="/historia">Historia</Link>
                      </p>
                      <p>
                      <Link to="/miljo">Miljö</Link>
                      </p>
                      <p>
                      <Link to="/team">Vårt Team</Link>
                      </p>

                      <p>
                      <Link to="/akademi">Akademi</Link>
                      </p>
                    </div>
            
                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase  mb-4 text-white">
                        Socialt
                      </h6>
                      <p>
                        
                      </p>
                      
                        <a href="https://www.instagram.com/gbgfast/"><img width="20px" alt="instagram"  src="/img/instagram.png" ></img></a>
                      
                      
                      <p>
                       <a href="https://sv-se.facebook.com/gbgfast"><img width="20px" alt="facebook" src="/img/facebook.png" ></img></a>
                      </p>
                    </div>
            
                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase  mb-4 text-white">
                        Kontakt
                      </h6>
                      <p>
                      Aschebergsgatan 41, 411 33 GÖTEBORG
                      </p>
                      <p>
                        <a href="tel:031818500">Tel: +46 (0)31 81 85 00</a>
                      </p>
                      <p>
                        <a href="mailto:info@gbgfast.se">Mail: info@gbgfast.se</a>
                      </p>

                    </div>
                  </div>
                </div>
            
              <div className="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                © 2021 Copyright:
                Gbgfast
              </div>
            </footer>
        )
    }
}




