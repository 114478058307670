import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';
import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';

import Contact from '../components/forms/contact';
import Footer from '../components/footer';
export default class charbel extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
    }; 
    this.getNestedObject = this.getNestedObject.bind(this)
} 
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }

componentDidMount() {
  document.title = 'Charbel';

  this.getData();
  
  
}
getNestedObject = (nestedObj, pathArr) => {


  return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    

}
eval2(data) {
  data = this.getNestedObject(this.state.deals, data)

  if(data !== undefined) {
    data = data.toLocaleString()

  }
  else {
    data = undefined
  }
  return data
}
    render() {
        return(

                 <div>
            <Header></Header>
            <Fullvid height="80vh" img="./img/hasan Profil.jpg" displayImg={true} header="CHARBEL YOUNES" button={false} text="Customer Service | Borådgivning"></Fullvid>
            <Contentblock>                
                <Textsection header="Vem är Charbel?" paragraph="Jag är utåtriktad med ambitioner att uppnå lika mål i arbetet och livet. Väldigt positiv person som alltid utför de utmaningar jag blivit tilldelad. Jag är en perfektionist som alltid vill leverera högsta kvalité . Jag är en väldigt hjälpsam person som alltid brytt mig om andra."></Textsection>
                <Contentblock>
                </Contentblock>
                </Contentblock>


            <Contentblock>
                <Footer></Footer>
            </Contentblock>
        </div>

    )
}
}