import React, { Component } from 'react'
export default class Imagegrid2 extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      showFull: false,
      windowWidth: window.innerWidth,
    }; 

    this.showFullBtn = this.showFullBtn.bind(this)
} 
showFullBtn () {
  this.setState({showFull:true})
}

handleResize = (e) => {
  this.setState({ windowWidth: window.innerWidth });
};
componentDidMount() {
  window.addEventListener("resize", this.handleResize);

}
    render() {


        return(
          <div>

            {this.state.showFull || this.state.windowWidth < 1400  ?
        <div className="container-xl" >
        <div className="row"> 
        <div className="grid_bilder">
        {
              this.props.images.map((key,i) => {
                return (
                  
              <div id={key == "floorplan.jpg" && "planlosning"} className="grid_bild" style={{cursor:"pointer"}} onClick={() =>this.props.buttonFunc(i)} key={i} >
                <img  alt="objektsbild" src={"/deals/img/" + (this.props.id).replace("..", "") +"/" +key} />
                
              </div>)
              
            })
               }

      </div>

      </div>

        </div> : 
        <div className="container-xl text-center" >
          <div className="row">
            <div className="col-8">
              <div className="grid_bild2" style={{height:"35vh"}}><img alt="bild" src={"/deals/img/" + this.props.id.replace("..", "") + "/1.jpg"}></img>

              </div>
              
              </div>
              <div className="col-4">
              <div className="grid_bild2" style={{height:"35vh"}}><img alt="bild" src={"/deals/img/" + this.props.id.replace("..", "") + "/2.jpg"}></img>
   
              </div>             </div>

            
            </div>
            <div className="row mt-4 mb-4">
            <div className="col-3">
              <div className="grid_bild2"><img alt="bild" src={"/deals/img/" + this.props.id.replace("..", "") + "/3.jpg"}></img>

              </div>            </div>
              <div className="col-3">
              <div className="grid_bild2" ><img alt="bild" src={"/deals/img/" + this.props.id.replace("..", "") + "/4.jpg"}></img>

              </div>             </div>
              <div className="col-3">
              <div className="grid_bild2"><img alt="bild" src={"/deals/img/" + this.props.id.replace("..", "") + "/5.jpg"}></img>

              </div>              </div>
              <div className="col-3">
              <a onClick={this.showFullBtn}  href="#/">
              <div className="grid_bild2"><img alt="bild" src={"/deals/img/" + this.props.id.replace("..", "") + "/6.jpg"}></img>
              <div className="semer" style={{opacity:"1", backgroundColor:"    background: rgba(0,0,0,0.8)"}}>
                <p className="fs-5">Visa fler bilder</p></div>
              </div></a>              </div>
              
            </div>

          </div>
 
        }
        </div>
        )
    }
}




