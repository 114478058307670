
import React, { Component } from 'react';

export default class Slideshow extends Component {
    constructor(props) { 
        super(props); 
        this.state = {
            activeimage: 0,
            showVisible:false,
        }; 
        this.nextImage = this.nextImage.bind(this)
    } 
    isFloorPlan = (element) => element == "floorplan.jpg"
    nextImage (direction) {
        
       
        if(direction==="left") {
            if((this.state.activeimage -1) < 0) {
                this.setState({activeimage:this.props.images.length-1})

            }
            else {
                this.setState({activeimage:this.state.activeimage-1})

            }
        }
        else if (direction==="right") {
            if((this.state.activeimage +1) > this.props.images.length-1) {
                this.setState({activeimage:0})

            }
            else {
                this.setState({activeimage:this.state.activeimage+1})

            }
        }
    }
    showToggleOff () {
        this.setState({showVisible:false})
    }
    showToggleOn(index) {
        this.setState({showVisible:true, activeimage:index})
        console.log(this.state.showVisible)
        
    }
    showToggleFP() {
        this.setState({showVisible:true, activeimage:this.props.images.findIndex(this.isFloorPlan)})
        console.log(this.state.showVisible)
    }



    render() {
    return(
        <div>
             {this.state.showVisible && 
             <div>
                             <div style={this.state.showVisible ? {display:"block"} : {display:"none"} } onClick={this.showToggleOff.bind(this)}><span className="close" style={{cursor:"pointer", position:"fixed"}}></span></div>

        <div className="slideshow">
        

             <i class="arrow left"  onClick={() => this.nextImage("left")}></i>
             <i class="arrow right" onClick={() => this.nextImage("right")}></i>


            <div>
                <img alt="slideshowimg" src={"/deals/img/" + (this.props.id).replace("..", "") +"/" +this.props.images[this.state.activeimage]}></img>

            </div>

        </div>
        </div>
        }
        </div>
    )}

}