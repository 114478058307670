import React, { Component } from 'react'

export default class Card extends Component {
   formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    
    return "+46 (0)" + str.slice(1, 4) + "-" + str.slice(4,6) + " " +str.slice(6,8) + " " + str.slice(8)

  }

    render() {


        return(
          <div className="container-xxl p-0 cardObj" >
            <div className="" style={{zIndex: "3",width:this.props.width, border:'0', margin:this.props.margin, overflow:"hidden"}}>
            <div className="row" style={{backgroundColor:this.props.background}}>
             
                
                <div className="card-body col mt-5 " style={{paddingLeft:this.props.paddingLeft}}>
                  <h5 className="card-title">{this.props.title}</h5>
                  <p className="card-text fs-6 m-0">{this.props.yrke}</p>
                  <p className="card-text fs-6 m-0"><a href={"mailto:" + this.props.desc}>{this.props.desc}</a></p>
                  <p className="card-text fs-6 m-0 text-muted">{this.formatPhoneNumber(this.props.tel)}</p>
                  <p className="card-text fs-6 mt-5 m-0">{this.props.isMainUser}</p>

              </div>
              <div className="col" style={{maxHeight:"300px"}}>

              <img src={this.props.img} className="img-fluid" style={{width:"100%",height:"300px", objectFit:"cover"}} alt="..." />
              </div>
            </div>
          </div></div>

        )
    }
}




Card.defaultProps = {
  title: "Leif Andersson",
  img: "/img/leif_t.png",
  desc: "Beskrivning",
  yrke: "Yrke",
  tel: "Tel:",
  background: "",
  paddingLeft:"",
  margin:"",
  width:"100%",
  isMainUser:"",

};

