import React, { Component } from 'react'
import {Link} from "react-router-dom";

export default class Objektspain extends Component {

  getNestedObject = (nestedObj, pathArr) => {


    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
      

}
  eval2(data) {
    
    data = this.getNestedObject(this.props.data, data)
    return data
  }
    render() {
        

        return (
          <Link to={`/villa/${ this.props.data["id"] }`}>
 
            <div className="fastighet2"  style={{position:"relative"}}>
            <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    > {this.props.data["features"] &&

                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">FEATURES</p>
                      </div>}
                      {this.props.data["video_url"] &&

                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">TAKE A TOUR</p>
                      </div>}
                    </div>
            
              
              <div className="fastighetimg2">
                  <div style={{position:"absolute", top:"0", left:"0",width:"100%", height:"100%",     background: "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)"
}}></div>
                  <img alt="houseimg" src={this.props.data["images"]["image"][0]["url"]}  />

              </div>
              
              <div className="fastighetinfo2" >
              <p >{this.eval2(["province"])}</p>
              <h2>{this.props.data["town"]} </h2>
                {this.eval2(["beds"]) ?
                <p className="fs-5">{this.eval2(["surface_area","built"]) + " M2 " + this.props.data["beds"] + " ROK "} {Number(this.props.data["price"]).toLocaleString()} EUR</p>
              : <p className="fs-5">{Number(this.props.data["price"]).toLocaleString()} EUR</p>

              
              }

                </div>
            </div>
            </Link>


          )
    }
}




