import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';
import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';

import Contact from '../components/forms/contact';
import Footer from '../components/footer';
export default class hasan extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
    }; 
    this.getNestedObject = this.getNestedObject.bind(this)
} 
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }

componentDidMount() {
  document.title = 'Hasan';

  this.getData();

}
getNestedObject = (nestedObj, pathArr) => {


  return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    

}
eval2(data) {
  data = this.getNestedObject(this.state.deals, data)

  if(data !== undefined) {
    data = data.toLocaleString()

  }
  else {
    data = undefined
  }
  return data
}
    render() {
        return(

                 <div>
            <Header></Header>
            <Fullvid height="80vh" img="./img/hasan Profil.jpg" displayImg={true} header="HASAN KAIS" button={false} text="Customer Service | Borådgivning"></Fullvid>
            <Contentblock>                
                <Textsection header="Vem är Hasan?" paragraph="Jag är glad, social och driven person med höga ambitioner i mitt yrkesliv. Jag strävar alltid efter att höja ribban ännu högre tills jag överträffat mina kunders förväntningar. Levererar alltid perfektion i mitt arbete och det resulterar till dom nöjdaste kunderna."></Textsection>
                <Contentblock>
                </Contentblock>
                </Contentblock>


            <Contentblock>
                <Footer></Footer>
            </Contentblock>
        </div>

    )
}
}