import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';

import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';

export default class nyproduktion extends Component {
    componentDidMount() {
        document.title = 'Nyproduktion';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/nyproduktion.mp4" brightness="brightness(60%)"  header="NYPRODUKTION" text="Se det senaste bygget och investera i nyproduktion" button={false}></Fullvid>
  
                <div id="content1">

                <Contentblock>
                    <div className="text-center">
                    <Textsection alignment2="text-center" header="Aktuella projekt" paragraph="Kolla in våra senaste projekt">                  
             
                    </Textsection>
                    </div>
                    <div className="container-xl mt-3 mb-5">

              <h1 className="mt-5 text-center">Slutsålt</h1>
              <div className="fastighet">
              <div className="fastighetimg">
                <img alt="ny produktion" src="/img/nyprod.webp" />
              </div>
              <div className="fastighetinfo">
                <h3>Bovallstrand</h3>
                <h2>Bovalls Höjd</h2>
              </div>
            </div>
            </div>
              
                    

                 </Contentblock>
                 </div>

                <Contentblock>
                    
                    <Footer></Footer>
                </Contentblock>
            </div>

        )
    }
}