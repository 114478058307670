import React, { Component } from 'react'
import {Link} from "react-router-dom";

export default class Fullvid extends Component {

    render() {
        


        return(
        <div className="main1" id="maklaremain" style={{height: this.props.height}}>

            <div className="overlay" id={this.props.displayImg ? 'overlay3' : ''} style={this.props.height==="100vh" ? {marginTop: this.props.margin + " "}:null}>
              <h1 style={{color: 'white'}}>{this.props.header}</h1>
              {
                this.props.buttonFunc ? 
              
              <button onClick={this.props.buttonFunc} id="myBtn" style={{display:this.props.button ? '' : 'none'}}>{this.props.buttontext}</button> : 
              <Link to={this.props.target}>
              <button  id="myBtn" style={{display:this.props.button ? '' : 'none'}}>{this.props.buttontext}</button>
              </Link>

              }

              <h2 style={{display:this.props.button ? 'none' : '', fontSize:"2rem", color: 'white'}}>{this.props.text}</h2>

            </div>
           
            <div style={this.props.arrow ? {position: 'absolute', bottom: '4rem'} : {display:"none"}} >
            <Link to={{hash:"#content1"}}>
            <div className="arrowdiv" ></div>
            </Link>
            </div>
            <video playsInline autoPlay loop muted style={{filter: this.props.brightness + " " + this.props.blur, height:this.props.height, display:this.props.displayImg ? 'none' : ''}}>
              <source src={this.props.img} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {this.props.children}
            <img alt="topimage" src={this.props.img} style={{filter: this.props.brightness + " " + this.props.blur,display:this.props.displayImg ? '' : 'none'}}/>

            
          </div>

        )
    }
}

Fullvid.defaultProps = {
    arrow: true,
    target: "",
    img: '/vids/Front.mp4',
    height: "100vh",
    button:true,
    buttontext: "SE VAD VI GÖR FÖR KLIMATET",
    displayImg: false,
    blur: "",
    brightness:"",
    margin:"",
    

  };