import React, { Component } from 'react'
import {Link} from "react-router-dom";
import Slide from 'react-reveal/Slide';
import '@animated-burgers/burger-squeeze/dist/styles.css' 
import Burger from '@animated-burgers/burger-squeeze' 



export default class Header extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active:false,
      hamburgerposition: "absolute",
      logoposition:"absolute",
      pointerevents: "",
      height:"5.5rem",
      sell: "none",
      about: "none",
      windowWidth: window.innerWidth,

      buy:"none",
      hamOpen: false,


    }
  }
  menuClick = () => {
    console.log("Click")
    console.log(this.state)
    if(!this.state.active) {
      this.setState({
        active:true,
        opacity:"1",
        pointerevents: "all",
        logoposition:"absolute",
        hamburgerposition:"absolute",
        hamOpen: true,
        
      })

    }
    else {
      this.setState({
        active:false,
        opacity:"",
        pointerevents: "",

        logoposition:"absolute",
        hamburgerposition:"absolute",
        hamOpen: false,

      })

    }
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  menuHover = (menu) => {
    
    if(menu === "buy") {
      this.setState({
        buy:"",
        sell:"none",
        about:"none"


      })
      if(this.state.windowWidth > 1000) {

      document.getElementById("maklaremain").style.filter = "brightness(20%)"
      }
    }
    else if(menu==="sell") {
      this.setState({
        sell:"",
        buy:"none",
        about:"none"


      })
      if(this.state.windowWidth > 1000) {

      document.getElementById("maklaremain").style.filter = "brightness(20%)"
      }
    }
    else if(menu==="about") {
      this.setState({
        sell:"none",
        buy:"none",
        about:""

      })
      if(this.state.windowWidth >= 1000) {

      document.getElementById("maklaremain").style.filter = "brightness(20%)"
      }
    }
    if(menu==="other") {
      this.setState({
        height:"5.5rem",
        sell:"none",
        buy:"none",

      })
    }
    else {
      this.setState({
      })
    }

  }
  menuLeave = (menu) => {
    this.setState({
      height:"5.5rem",
      sell:"none",
      buy:"none",
      about:"none"

    })
    document.getElementById("maklaremain").style.filter = "brightness(100%)"

    

  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

  }


    render() {
        return(
    <div>
        <Link to="/landing" >
        <div id="logo" className="logo" style={{position:this.state.logoposition}}>
        <img alt="gbgfast" src="/img/vitalogganhemsida.svg" />
        </div>
        {/*}
        <div className="search">
            <div  style={{cursor:"pointer"}}>
            <img src="/img/search.svg" width="30px"></img>

            </div>
        

        </div>*/}
        </Link>
        <header onMouseLeave={() => this.menuLeave()}  style={{height:this.state.height, backgroundColor:this.props.background}}>
          <nav style={{opacity:this.state.opacity, pointerEvents: this.state.pointerevents}}>
                <div className="container-fluid text-center newNav" >
                  <div className="row justify-content-center gx-5" >

                    <div className="col-auto text-center" >
                    <div  onClick={() => this.menuHover("sell")} style={{cursor:"pointer",paddingTop:"2rem"}}>
                        <h1>Sälja</h1>

                      </div>
                      <div className="submenupad">
                      <Slide cascade top duration={400}>

                      
                      <div className="submenu1" style={{display:this.state.sell, whiteSpace: "nowrap", cursor:"pointer"}}>
                      <div>
                      <Link to="/sell"><h2>Sälj med oss</h2></Link>

                        </div>
                        <div>
                        <Link to="/sell"><h2>Boka värdering</h2></Link>

                        </div>

                      <div>
                      <Link to="/spanien"><h2>Spanien</h2></Link>

                      </div>
                     

                      <div> <Link to="/#underhand"><h2>
                        Underhand
                        </h2>                      </Link>

                      </div>
                      {/*}
                      <div><h2>
                        Kommersiella
                        </h2>
      </div>*/}
                      <div>
                      <Link to="/villaguide"><h2>Säljguide Villa</h2></Link>
                      </div>
                      <div>
                      <Link to="/bostadguide"><h2>Säljguide Bostadsrätt</h2></Link>
                      </div>
                      </div>
                      </Slide>

                      </div>
                    </div>
                    

                    <div className="col-auto text-center" >
                      <div onClick={() => this.menuHover("buy")} style={{cursor:"pointer",paddingTop:"2rem"}}>
                      <h1>Köpa</h1>

                      </div>
                      <div className="submenupad">

                      <Slide cascade top duration={400}>
                      <div className="submenu1" style={{display:this.state.buy, whiteSpace: "nowrap", cursor:"pointer"}}>
                        <div>
                        <Link to="/listings"><h2>Till salu</h2></Link>

                        </div>

                      <div>
                      <Link to="/spanien"><h2>Spanien</h2></Link>
                      </div>

                      <div>
                      <Link to="/nyproduktion"><h2>Nyproduktion</h2></Link>
                      </div>
                      {/*}
                      <div><h2>
                        Kommersiella</h2></div>*/}
                      <div>
                      <Link to="/kopguidebostadsratt"><h2>Köpguide bostadsrätt</h2></Link>
                      </div>
                      <div>
                      <Link to="/kopguidevilla"><h2>Köpguide villa</h2></Link>
                      </div>
                      

                      </div></Slide>
                      </div>


                    </div>
                    <div className="col-auto text-center" >
                    <div  onClick={() => this.menuHover("about")} style={{cursor:"pointer",paddingTop:"2rem"}}>
                        <h1>Om oss</h1>

                      </div>
                      <div className="submenupad">

                      <Slide cascade top duration={400}>

                      <div className="submenu1" style={{display:this.state.about, whiteSpace: "nowrap", cursor:"pointer"}}>
                      <div>

                      <Link to="/historia"><h1>historia</h1></Link>
                      </div>


                        <div>
                     <Link to="/team"><h1>Vårt team</h1></Link>
                     </div>

                      

                     <div>

                     <Link to="/miljo">
                      <h1>miljö</h1>
                      </Link>
                      </div>



                      </div>
                      </Slide>

                      </div>
                    </div>





                    <div className="col-auto text-center" onMouseEnter={() => this.menuHover("other")}>
                     <div style={{cursor:"pointer",paddingTop:"2rem"}}>
                     <Link to="/akademi"><h1>Akademi</h1></Link>

                      </div>
                      


                    </div>

                    
                    

                  </div>




                </div>


              {/*}
              <li><Link to="/team" >Kontakt</Link></li>*/}
          </nav>
          <div className="hamburger-toggle"  style={{position:this.state.hamburgerposition}} >
          <Burger direction="right" onClick={this.menuClick} isOpen={this.state.hamOpen}/>

          </div>



        </header>
      </div>

        )
    }
}
Header.defaultProps= {
  background:"",
}
