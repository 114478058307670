export default function Map(props) {
    
    var coords = {
        lat: props.lat,
        lng: props.lng,

    }
    let maplink = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCcAZ83j0rj0nRpApcEDKh7MulpSWWmLEU&q=" + coords.lat + "," + coords.lng + "&zoom=15"

    return (
        <div className="mt-3" style={{height:"50vh"}}>
            <iframe title="Karta" width="100%" height="100%" src={maplink}></iframe>
        </div>
    )



}