import React, { Component } from "react";
import Tabs from "../components/tabs";
import Reel from "../components/reel";
import Header from "../components/header";
import Shopspain from "../components/shopspain";
import Contentblock from "../components/contentblock";
import Footer from "../components/footer";
import Fullvid from "../components/Fullvid";
import Form from "../components/form";
import Textsection from "../components/textsection";
import SmoothText from "../components/smoothText";
import { Checkbox } from "antd";
import Spanienform from "../components/forms/spanien";
import FormModal from "../components/formmodal";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
export default class premiumvillas extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = React.createRef();
  }
  buttonFunc() {
    this.handleToggle.current.modalToggle();
  }
  componentDidMount() {
    document.title = "Till salu";
  }

  render() {
    return (
      <div id="head">
        <FormModal ref={this.handleToggle}>
          <Spanienform title={"Allmänt intresse för spanien"}></Spanienform>
        </FormModal>
        <Header></Header>
        <Fullvid
          height="40vh"
          img="/vids/spanien.mp4"
          brightness="brightness(60%)"
          header=""
          text=""
          button={false}
        >
          <SmoothText
            speed={2500}
            fontSize={"1.5rem"}
            wordList={[
              "Skandinaviens största utbud av spanska ensamrätter ",
              "Pålitlig och helhets löst köpprocess från intresse till affär ",
              "Tillgång till exklusiva ensamrätter",
              "Ta en tur i området och favorit bostaden utan att lämna soffan ",
            ]}
          ></SmoothText>
        </Fullvid>
        <Textsection
          header="WELCOME TO SPAIN"
          header2=""
          paragraph=""
        ></Textsection>

        <div className="container-fluid">
          <div className="row">

            <div className="col-lg text-center hoverSpain p-5 pt-0">

              <h1 >HIGHEST VALUE</h1>
              <Carousel>
                <Carousel.Item>
                  <div className="fastighetimg3">
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "1",

                        background:
                          "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          width: "130px",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                      </div>
                    </div>
                    <img
                      alt="bild"
                      className="spainIm"
                      style={{ filter: "brightness(75%)" }}
                      src="https://casas-ambiente.com/media/images/properties/o_1cqgngsm3c0518kqgol11a51jol36.jpg"
                    />
                    <div
                      className="fastighetinfo text-start"
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <h3 style={{ color: "white" }}>Alicante</h3>
                      <h2 style={{ color: "white" }}>Calpe</h2>
                      <p style={{ color: "white" }}>336 M2 ROK 1795000 EUR </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="fastighetimg3">
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "1",

                        background:
                          "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          width: "130px",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                      </div>
                    </div>
                    <img
                      alt="bild"
                      className="spainIm"
                      style={{ filter: "brightness(75%)" }}
                      src="https://www.ghcostablanca.com/objetos/temp/source/ghcostablanca/ghcostablanca-propiedades_72cd9cf6bafb1e21a3efdbe57f2a64eb377d700b-source.jpg"
                    />
                    <div
                      className="fastighetinfo text-start"
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <h3 style={{ color: "white" }}>Alicante</h3>
                      <h2 style={{ color: "white" }}>Calpe</h2>
                      <p style={{ color: "white" }}>
                        12 rok 6500 kvm 20 000 000 kr
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="fastighetimg3">
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "1",

                        background:
                          "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          width: "130px",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                      </div>
                    </div>
                    <img
                      alt="bild"
                      className="spainIm"
                      style={{ filter: "brightness(75%)" }}
                      src="https://casas-ambiente.com/media/images/properties/o_1ec2p95091lo9rcs13tc9k05p23b.jpeg"
                    />
                    <div
                      className="fastighetinfo text-start"
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <h3 style={{ color: "white" }}>Alicante</h3>
                      <h2 style={{ color: "white" }}>Calpe</h2>
                      <p style={{ color: "white" }}>
                        12 rok 6500 kvm 20 000 000 kr
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
              <ul className="mt-5 ms-1 p-0 text-start">
              <li >Highest value per price properties </li> 
              <li>Limited market access </li> 
              <li>Limited bonuses within property deal </li> 
              <li>Limited market exposure with </li> 
              </ul>
              <Link to={{ hash: "#head" }}>
                <input
                  type="submit"
                  onClick={() => this.buttonFunc()}
                  value="Find out more!"
                  className="btn mt-5"
                  style={{ height: "60px", padding: "0", fontWeight: 600 }}
                />
              </Link>

            </div>
            <div className="col-lg text-center ms-lg-5 hoverSpain p-5 pt-0">
              <h1>OFFMARKET</h1>

              <Carousel>
                <Carousel.Item>
                  <div className="fastighetimg3">
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "1",

                        background:
                          "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          width: "130px",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                      </div>
                    </div>
                    <img
                      alt="bild"
                      className="spainIm"
                      style={{ filter: "brightness(75%)" }}
                      src="https://casas-ambiente.com/media/images/properties/o_1cqgngsm3c0518kqgol11a51jol36.jpg"
                    />
                    <div
                      className="fastighetinfo text-start"
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <h3 style={{ color: "white" }}>Alicante</h3>
                      <h2 style={{ color: "white" }}>Calpe</h2>
                      <p style={{ color: "white" }}>336 M2 ROK 1795000 EUR </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="fastighetimg3">
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "1",

                        background:
                          "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          width: "130px",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                      </div>
                    </div>
                    <img
                      alt="bild"
                      className="spainIm"
                      style={{ filter: "brightness(75%)" }}
                      src="https://www.ghcostablanca.com/objetos/temp/source/ghcostablanca/ghcostablanca-propiedades_72cd9cf6bafb1e21a3efdbe57f2a64eb377d700b-source.jpg"
                    />
                    <div
                      className="fastighetinfo text-start"
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <h3 style={{ color: "white" }}>Alicante</h3>
                      <h2 style={{ color: "white" }}>Calpe</h2>
                      <p style={{ color: "white" }}>
                        12 rok 6500 kvm 20 000 000 kr
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="fastighetimg3">
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "1",

                        background:
                          "linear-gradient(0deg, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.4) 21%, rgba(189,189,189,0) 100%)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: "1rem",
                          width: "130px",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          height: "45px",
                          backgroundColor: "#686464",
                          zIndex: "3",
                          borderRadius: "0 0 0 0",
                          padding: "0.4rem",
                          color: "white",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                      </div>
                    </div>
                    <img
                      alt="bild"
                      className="spainIm"
                      style={{ filter: "brightness(75%)" }}
                      src="https://casas-ambiente.com/media/images/properties/o_1ec2p95091lo9rcs13tc9k05p23b.jpeg"
                    />
                    <div
                      className="fastighetinfo text-start"
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        left: "20px",
                        zIndex: "3",
                      }}
                    >
                      <h3 style={{ color: "white" }}>Alicante</h3>
                      <h2 style={{ color: "white" }}>Calpe</h2>
                      <p style={{ color: "white" }}>
                        12 rok 6500 kvm 20 000 000 kr
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
              <ul className="mt-5 ms-1 p-0 text-start">
              <li >Limited exposure</li> 
              <li>Exclusive rights</li> 
              <li>Reduced competitive prices</li> 
              <li>Priority Access</li> 
              </ul>
              <Link to={{ hash: "#head" }}>
                <input
                  type="submit"
                  onClick={() => this.buttonFunc()}
                  value="Find out more!"
                  className="btn mt-5"
                  style={{ height: "60px", padding: "0", fontWeight: 600 }}
                />
              </Link>
            </div>
          </div>
        </div>



        {/*<Tabs></Tabs>*/}
        <div className="text-center"></div>

        <div className="container mt-5 pt-4">
          <div className="row">
            <div className="col">
              <div className="">
                <Textsection
                  header="Spain"
                  header2=""
                  paragraph="Many people dream of buying a house in Spain. Warm evenings, delicious food and high quality of life are just a few of the reasons to buy accommodation in Spain. With more than 2,500 hours of sunshine per year and hundreds of beaches, it's easy to understand why Spain is so popular for Swedes who want a pleasant life. If you are thinking of moving to sunny Spain, will help you find the right one. Discover the most popular areas and find your dream house in sunny Spain."
                ></Textsection>
              </div>
              <ul style={{columns:1, width:"60%"}} className="m-auto pb-4 mb-5">
                <li>Exclusive properties with limited market access </li>
                <li>VIP tours of the property and area in Spain with dedicated realtors in Spain and Scandinavia.  </li>
                <li>Free personalized help and support from Scandinavia to Spain. </li>
                <li>Only reliable way to buy your new and desired home, from home.   </li>
                <li>Over 25 real estate agencies helping you find the 1 home you love. </li>

              </ul>
              
            </div>

          </div>
        </div>
        <div className="col-7 m-auto">
              <iframe
                className="objektVideo "
                src={"https://www.youtube.com/embed/5Ye6YXRrx8g"}
              ></iframe>
          </div>
        <div className="text-center">
          <Link to={{ hash: "#head" }}>
            <input
              type="submit"
              onClick={() => this.buttonFunc()}
              value="Find out more!"
              className="btn mt-5"
              style={{ height: "60px", padding: "0", fontWeight: 600 }}
            />
          </Link>
        </div>
        <Textsection
          header="Discover your new home"
          header2=""
          paragraph=""
        ></Textsection>

        <Contentblock>
          <Shopspain></Shopspain>
        </Contentblock>
        <Contentblock>
          <Footer></Footer>
        </Contentblock>
      </div>
    );
  }
}
