import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';
import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';

import Contact from '../components/forms/contact';
import Footer from '../components/footer';
export default class leif extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
    }; 
    this.getNestedObject = this.getNestedObject.bind(this)
} 
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }

componentDidMount() {
  document.title = 'Leif';

  this.getData();
  
  
}
getNestedObject = (nestedObj, pathArr) => {


  return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    

}
eval2(data) {
  data = this.getNestedObject(this.state.deals, data)

  if(data !== undefined) {
    data = data.toLocaleString()

  }
  else {
    data = undefined
  }
  return data
}
    render() {
        return(

                 <div>
            <Header></Header>
            <Fullvid height="80vh" img="./img/leif.jpg" displayImg={true} header="LEIF ANDERSSON" button={false} text="REG. FASTIGHETSMÄKLARE"></Fullvid>
            <Contentblock>                
                <Textsection header="Vem är Leif?" paragraph="Vågar påstå att jag är en engagerad mäklare med fokus på nöjdast kunder och bäst betalt. Jag får ungefär 50% av mina uppdrag genom rekommendation vilket jag är stolt över. Att arbeta som mäklare är för mig mycket stimulerande vilket ger mig förmånen att dagligen få träffa fantastiska människor. Att hjälpa dig sälja din bostad enkelt och med maximal ekonomisk utväxling är något jag brinner för."></Textsection>
                <Contentblock>
                </Contentblock>
            <div id="form" className="container p-5" style={{ backgroundColor: "#fef3e4" }}>
                <Contact agentId="MDAtMXwwMDAwMDAwOTg3N3w0" agent={" Leif?"}></Contact>

              </div>            </Contentblock>
        <Textsection header="Ett urval av tidigare försäljningar" paragraph=""></Textsection>
        <div className="container fastighetgrid grid_2">
               {
      this.state.deals &&
      Object.keys(this.state.deals).map((key,i) => {
      console.log(this.state.deals[key])
      if(this.state.deals[key]["publishingInformation"]["isPreview"] === false && this.state.deals[key]["publishingInformation"]["saleStatus"] === "ENUMS_SALE_STATUS_SOLD" && this.state.deals[key]["users"].filter(user => (user.id === "MDAtMXwwMDAwMDAwOTg3N3w0")).length > 0) {
       
        return (
        <div className="fastighet" style={{pointerEvents:"none"}}>
            <div className="fastighetimg" >
            <div className="semer" style={{opacity:"1", backgroundColor:"    background: rgba(0,0,0,0.8)"}}>
                <p className="fs-4">Såld</p></div>

            <img alt="houseimg" src={"/deals/img/" + this.state.deals[key]["id"].replace("..", "") + "/main.jpg"} />
            </div>
            <div className="fastighetinfo">
            <p >{this.eval2([key,"location","city"]) + " / " + this.eval2([key,"location","district"])}</p>
            <h2 >{this.eval2([key,"location","streetAddress"])}</h2>
            {this.eval2(["saleInformation","finalPrice"]) &&
            <p className="fs-4">Pris: {this.eval2([key,"saleInformation","finalPrice"])} kr</p>
            }
          </div>

          </div>

        )

      }
      return null
      
    }).reverse()
       }
               </div>
            <div>
        
      </div>
      


            <Contentblock>
                <Footer></Footer>
            </Contentblock>
        </div>

    )
}
}