import React from 'react'
import FormSpanien from '../FormSpanien'
import Textsection from '../textsection'
import {Checkbox} from "antd";
export default function Spanienform (props) {
  
/*Har du en exklusiv bostad eller behov att sälja utan att någon vet så listar vi den underhand. Matchning sker löpande mot utvalda intressenter i hela Europa.*/
 
        return(
            <div  style={{color:"white"}}>
            <FormSpanien title={props.title} id={props.id} modalToggleFinish={props.modalToggleFinish}>
            <Textsection marginTop="0" color="white" alignment2="center" header="Intresserad av Spanien?" paragraph="Få kontakt med en mäklare som är expert på Spanien och

boka en kostnadsfri rådgivning">

            </Textsection>                        
            <div className="row">
                    <div className="col-75">
                    <input type="text" id="consumerFamilyName" name="consumerFamilyName" placeholder="Namn*" required/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-75">
                    <input type="text" id="consumerMsisdn" name="consumerMsisdn" placeholder="Mobilnummer*" required/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-75">
                    <input type="text" id="consumerEmail" name="consumerEmail" placeholder="Email*" required/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-75">
                    <input type="text" id="taskDescription" name="taskDescription" placeholder="Meddelande"/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-75">
                    
                    <Checkbox style={{color:"white"}}>Jag samtycker till behandling av mina personuppgifter enligt vår personuppgiftspolicy</Checkbox>                                

                    </div>
                </div>




        </FormSpanien>
        </div>
        )
    
}




