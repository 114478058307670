import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';
import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Contact from '../components/forms/contact';
import Footer from '../components/footer';
export default class bo extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
    };
     
} 
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }

componentDidMount() {
  document.title = 'Bo';

  this.getData();
}
getNestedObject = (nestedObj, pathArr) => {


  return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    

}
eval2(data) {
  data = this.getNestedObject(this.state.deals, data)

  if(data !== undefined) {
    data = data.toLocaleString()

  }
  else {
    data = undefined
  }
  return data
}
    render() {
        return(

                 <div>
            <Header></Header>
            <Fullvid height="80vh" img="./img/Bosse färdig.png" displayImg={true} header="Bo Andersson" button={false} text="MÄKLARASSISTENT" ></Fullvid>
            <Contentblock>                
                <Textsection header="Vem är Bo?" paragraph="Jag är en drivande, positiv och engagerad person i allt jag företar mig, vilket gör privat- och arbetsliv både enklare och roligare. I mitt arbete är dessa egenskaper en stor fördel och ger en glad, säljande och omsorgsfull mäklare med fokus på att alltid ha ”nöjdast” kunder med bäst betalt i fokus."></Textsection>
                <div id="form" className="container p-5" style={{ backgroundColor: "#fef3e4" }}>
                <Contact agentId="MDAtMXwwMDAwMDAwOTkwMnw0" agent={" Bo?"}></Contact>

              </div> 
              <Textsection header="Ett urval av tidigare försäljningar" paragraph=""></Textsection>

                <div>
                <div className="container fastighetgrid grid_2">

{
      this.state.deals &&
      Object.keys(this.state.deals).map((key,i) => {
      console.log(this.state.deals[key])
      if(this.state.deals[key]["publishingInformation"]["isPreview"] === false && this.state.deals[key]["publishingInformation"]["saleStatus"] === "ENUMS_SALE_STATUS_SOLD" && this.state.deals[key]["users"].filter(user => (user.id === "MDAtMXwwMDAwMDAwOTkwMnw0")).length > 0) {
       
        return (
        <div className="fastighet" style={{pointerEvents:"none"}}>
            <div className="fastighetimg">
            <img alt="houseimg" src={"/deals/img/" + this.state.deals[key]["id"].replace("..", "") + "/main.jpg"} />
            <div className="semer" style={{opacity:"1", backgroundColor:"    background: rgba(0,0,0,0.8)"}}>
                <p className="fs-4">Såld</p></div>
            </div>
            <div className="fastighetinfo">
            <p >{this.eval2([key,"location","city"]) + " / " + this.eval2([key,"location","district"])}</p>
            <h2 >{this.eval2([key,"location","streetAddress"])}</h2>
            {this.eval2(["saleInformation","finalPrice"]) &&
              <p className="fs-4">Pris: {this.props.data["saleInformation"]["finalPrice"].toLocaleString()} kr</p>
            }
          </div>

          </div>

        )

      }
      return null
      
    }).reverse()
       }
       </div>
      </div>

            </Contentblock>



            <Contentblock>
                <Footer></Footer>
            </Contentblock>
        </div>

    )
}
}