import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Footer from '../components/footer';
import Textsection from '../components/textsection';
export default class kopguidevilla extends Component {
    componentDidMount() {
        document.title = 'Köpguide villa';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid arrow={false} target={{hash:"#content1"}} displayImg={true} height="80vh" img="/img/kopguidevilla.jpg" header="Köpguide Villa" buttontext="Läs mer"></Fullvid>
                <div id="content1">

                <Contentblock>
                    <Textsection header="Gbgfast's köpguide för villa!"  paragraph="De allra flesta går på en flera visningar innan man vet vad man är ute efter. Ju fler hus man tittar på, desto mer lär man sig om vad man egentligen letar efter och tycker är viktigt. Ibland dyker dock drömhuset upp vid första visningen och det gäller att vara förberedd. Utrusta dig med måttstock, papper, penna och ett litet frågebatteri till mäklaren med saker du undrar över.">

 
                    </Textsection>
                    <Textsection header="" header2="Lånelöfte"  paragraph="Det kan vara en god idé att göra en snabb undersökning för att ta reda på vad husen i området sålts för. Det finns information om slutpriser på nätet som t.ex. hemnet.se eller booli.se som kan ge en indikation över hur marknaden ser ut för just ”ditt” hus.">

 
                    </Textsection>
                    <Textsection header="" header2="Öppen budgivning"  paragraph="Gbgfast tillämpar öppen budgivning, vilket innebär att mäklaren löpande redovisar buden till både säljaren och alla budgivarna. Budgivarna har då har möjligheten att bjuda över varandra. Öppen budgivningslista innebär att den slutgiltiga köparen och säljaren får ta del av den lista där buden noterats under budgivningsprocessen efter avslutad affär.">

 
                    </Textsection>
                    <Textsection header="" header2="Vad kostar det att äga hus?"  paragraph="För villa, rad- eller fritidshus tillkommer årligen olika kostnader. Det handlar framför allt om driftskostnader som uppvärmning, el, sopor, vatten och avlopp men även fastighetsavgift samt underhåll av fastigheten. Det finns även en rad skatter och avgifter i samband med husköpet som du bör känna till.">

 
                    </Textsection>
                    <Textsection header="" header2="Stämpelskatt"  paragraph="När du köper en fastighet eller tomträtt, eller om du intecknar en fastighet måste du betala stämpelskatt som beror på fastighetens köpeskilling. Lagfartsavgiften är en engångsskatt som för närvarande är 1,5 % av köpesumman plus en expeditionsavgift om 825 kr. Om det är en juridisk person (exempelvis aktiebolag eller ekonomiska föreningar) som köper fastigheten är stämpelskatten 4,25 % av köpeskillingen. För att belåna fastigheten behöver man pantbrev som säkerhet. Pantbrevens värde följer fastigheten. Om du behöver låna mer än vad som finns uttagna pantbrev på fastigheten behöver du ta ut nya pantbrev. Detta kostar 2% av värdet på de nya pantbrev du behöver plus en expeditionsavgift om 375 kr.">
                    
                    <p className="fs-5">På <a style={{textDecoration: 'underline'}} href="https://www.lantmateriet.se/sv/">Lantmäteriets</a> sida kan du läsa mer om stämpelskatt.</p>
                    <ul style={{columnCount: '1'}}>
                        <li>Lagfart </li>
                        <p className="fs-5">När du köper en fastighet eller tomträtt ska du ansöka lagfart, vilket är en registrering av att du är ägare av fastigheten eller tomträtten. Ansökan skickas in till Lantmäteriet senast tre månader efter att du köpt fastigheten/tomträtten. Detta ombesörjs ofta av banken.</p>
                        <li>Pantbrev</li>
                        <p className="fs-5">Ett pantbrev är ett bevis för en inteckning. Idag är pantbrevet i ett dataregister. Pantbrevet utfärdas av Fastighetsinskrivningen. Om du belånar ditt hus registreras pantbrevet till banken eller långivaren som en säkerhet för lånet.</p>
                    </ul>

 
                    </Textsection>
                    <Textsection header="" header2="Fastighetsavgift"  paragraph="Den som äger ett småhus betalar fastighetsavgift med ett takbelopp om 7 687 kr (deklarationen 2018) kr för varje färdigbyggd bostadsbyggnad. Om 0,75% av taxeringsvärdet ger en lägre avgift ska du betala det i stället. Takbeloppet bestäms varje år utifrån förändringar i inkomstbasbeloppet.">
                    <p className="fs-5">För fastigheter som är bebyggda 2012 eller senare är man befriad från fastighetsavgift under de första 15 åren.</p>
                    <p className="fs-5">Fråga din mäklare eller besök <a style={{textDecoration: 'underline'}} href="https://www.skatteverket.se/privat/fastigheterochbostad/fastighetstaxering.4.18e1b10334ebe8bc80003523.html">Skatteverkets</a> hemsida för att få reda på vad beloppet för bostaden du är intresserad av.</p>
                    
                    </Textsection>´
                    <Textsection header="" header2="Äkta eller oäkta förening?"  paragraph="En bostadsrättsförening kan vara en äkta eller oäkta förening. Det vanligaste är äkta bostadsrättsföreningar som har minst 60 procent av sin inkomst som från s.k. kallad kvalificerad verksamhet dvs har 60 % av intäkter från de egna medlemmarna. En oäkta förening har alltså mer än 40% av sina intäkter från andra än sina medlemmar t.ex. uthyrning av affärslokal.">
                        <p className="fs-5">Som köpare är det viktigt att du känner till om föreningen anses vara äkta eller oäkta då detta påverkar de skattetrycket. En oäkta förening måste betala mer i skatt än en äkta, dessutom kan bostadsrättsägaren bli förmånsbeskattad. Det är också olika skattesats vid beräkning av reavinst vid försäljning.</p>
 
                    </Textsection>
                    <Textsection header="" header2="Tomträttsavgäld"  paragraph="Om ditt nya boende står på en tomträtt är det viktigt att se över avtal och friköpspris, avgiften för en tomträttsavgäld kan nämligen variera kraftigt mellan olika platser. Om avtalet snart går ut finns det också risk att avgiften kan komma att höjas kraftigt när ett nytt avtal börjar gälla.">
 
                    </Textsection>
                    <Textsection header="" header2="Servitut"  paragraph="Servitut är en rätt för en någon annan än ägaren att använda en fastighet eller byggnad. Det kan till exempel handla om rätten att bruka en väg, mark eller en brunn. Servitutsrätten gäller mellan fastigheterna, oavsett vem som är ägare.">   
 
                    </Textsection>
                    <Textsection header="" header2="Bostadens energiförbrukning"  paragraph="Uppvärmningskostnaden kan variera kraftigt över året och mellan milda och kalla vintrar. Därför har du som köpare rätt att få se en energideklaration. Om säljaren inte kan visa en energideklaration har du som köpare rätt att  be säljaren att göra en sådan på säljarens bekostnad.  Deklarationen görs av en oberoende expert och den är giltig i 10 år.">
                        <p className="fs-5">En energideklaration visar hur vilken energianvändning en fastighet har och innehåller information om den uppvärmda arean i huset, energianvändning, eventuella åtgärdsförslag för att minska energianvändning samt om en radonmätning är utförd och vad radonvärdet är. Du kan använda en energideklaration för att jämföra olika bostäder med varandra.</p>

 
                    </Textsection>
                    <Textsection header="" header2="Övriga huskostnader"  paragraph="Att bo i villa eller radhus kan också innebära kostnader du inte haft förut, såsom sophämtning, sotning, vattenförbrukning eller larm.">
                        <p className="fs-5">Ett hus kräver också att du har en extra buffert för sådant som behöver underhållas löpande. Tak behöver läggas om, fönster behöver målas och värmepannan kanske måste lagas.</p>
                    </Textsection>
                    <Textsection header="" header2="Måste jag besiktiga huset?"  paragraph="När du köper ett hus så köper man bostaden i det skick den befinner sig i på kontraktsdagen. Som köpare är du själv ansvarig att undersöka huset före ett köp för att få en uppfattning om husets skick. Därför rekommenderar vi på Gbgfast att man alltid ska göra en besiktning på huset innan man köper det, gärna med hjälp av en besiktningsman. En besiktningsman är opartisk och gör en professionell bedömning av bostaden och dess skick.">

 
                    </Textsection>
                    <Textsection header="" header2="Undersökningsplikt"  paragraph="När du har hittat det hus du vill ha, kan husets skick bli avgörande för det slutliga priset. Vid en vanlig visning finns normalt inte tillfälle att noggrant gå igenom huset och undersöka dess skick. Detta måste göras vid ett särskilt tillfälle. Enligt Jordabalkslagen har du som köpare av ett hus undersökningsplikt. Det betyder att du som köpare noggrant måste undersöka huset och dess skick. Alla fel som kunde ha upptäckts vid en noggrann granskning är köparens ansvar. För att vara säker på att du inte missar något och för att undvika framtida obehagligheter rekommenderar vi dig därför att anlita någon som yrkesmässigt utför överlåtelsebesiktningar."> 
                    </Textsection>
                    <Textsection header="" header2="Överlåtelsebesiktning"  paragraph="I en överlåtelsebesiktning gör besiktningsmannen en okulär besiktning av fastigheten och kontrollerar alla tillgängliga ytor i huset, samt övriga upplysningar från säljaren. Vad som exakt ingår i besiktningen kan skilja sig mellan olika besiktningsfirmor."> 
                    </Textsection>
                    <Textsection header="" header2="Dolda fel"  paragraph="Dolda fel är större fel eller brister som inte kunnat upptäckas av vare sig säljare, köpare eller besiktningsman. Felet ska inte heller kunna vara förväntat för bostadens ålder, pris eller skick. Det är säljaren som är ansvarig för dolda fel i tio år efter överlåtelsen. Läs mer om dolda fel här."> 
                    </Textsection>
                    <Textsection header="" header2="Frågor att ställa till mäklaren"  paragraph="Som köpare kan du kräva att mäklaren har kontrollerat allt som rör fastigheten, och köper du hus från en av våra mäklare kan du alltid få information om husets skick och kostnader. Mäklaren kan även hjälpa dig att ta fram en kalkyl över vad det skulle kosta att bo i huset."> 
                        <p className="fs-5">Här har vi sammanställt några viktiga frågor att ställa mäklaren inför ett husköp.</p>
                    </Textsection>
                    <Textsection header="" header2="Kostnader"  paragraph=""> 
                        <ul style={{columnCount: '1'}}>
                            <li>Finns en giltig energideklaration?</li>
                            <li>Vad är det för uppvärmningssystem?</li>
                            <li>Vad har det kostat årligen för nuvarande ägare?</li>
                            <li>Hur mycket pantbrev finns?</li>
                            <li>Är det kommunalt vatten &#38; avlopp eller har fastigheten eget avlopp/brunn? Om det är eget avlopp och vatten är de godkända av kommunen samt finns det aktuella vattenprov gjorda?</li>
                            <li>Ingår huset i en samfällighet? Tar samfälligheten ut någon avgift?</li>
                        </ul>
                    </Textsection>
                    <Textsection header="" header2="Ombyggnad"  paragraph=""> 
                    <ul style={{columnCount: '1'}}>
                        <li>Finns giltigta bygglov för de hus som är upprättade?</li>
                        <li>Finns det bygglov för utbyggnad?</li>
                        <li>Har det gjorts några renoveringar, och när gjordes de?</li>
                    </ul>
                    <p className="fs-6">Tveka inte att kontakta oss om du behöver stöd från en mäklare.</p>
                    <p className="fs-5">Nu är det dags för dig att köpa bostadsrätt. Kanske är detta din första bostad i livet eller köpt och sålt flera gånger, eller ska flytta till lägenhet från ett hus? Oavsett så hjälper vi på Gbgfast dig att få all information du behöver inför lägenhetsköpet. Med vår köpguide blir det lättare att förstå säljinformationen och fatta ett välinformerat beslut.</p>
                    <h2 className="text-center">Välkommen till Gbg Fast!</h2>
                    </Textsection>
                </Contentblock>
                <Contentblock>
                   
                </Contentblock>                
                </div>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>

            </div>
        )
    }
}