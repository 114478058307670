import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Footer from '../components/footer';
import Textsection from '../components/textsection';
export default class kopguidebostadsratt extends Component {
    componentDidMount() {
        document.title = 'Köpgudie bostadsrätt';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid arrow={false} target={{hash:"#content1"}} displayImg={true} height="80vh" img="/img/feskekyrka.jpg" header="Köpguide Bostadsrätt" buttontext="Läs mer"></Fullvid>
                <div id="content1">

                <Contentblock>
                    <Textsection header="Gbgfast's köpguide för bostadsrätt!"  paragraph="Nu är det dags för dig att köpa bostadsrätt. Kanske är detta din första bostad i livet eller köpt och sålt flera gånger, eller ska flytta till lägenhet från ett hus? Oavsett så hjälper vi på Gbgfast dig att få all information du behöver inför lägenhetsköpet. Med vår köpguide blir det lättare att förstå säljinformationen och fatta ett välinformerat beslut.">

 
                    </Textsection>
                    <Textsection header="" header2="Hur går ett lägenhetsköp till?"  paragraph="De allra flesta går på flera visningar innan man vet vad man är ute efter. Ju fler lägenheter man tittar på desto mer lär man sig om vad man tycker är viktigt. Utrusta dig med måttstock, papper, penna och ett litet frågebatteri till mäklaren med saker du undrar över.">

 
                    </Textsection>
                    <Textsection header="" header2="Lånelöfte"  paragraph="Om du har bestämt dig för att köpa bostadsrätt är det första du måste göra att skaffa ett lånelöfte från en bank eller bolåneinstitut. Du får då en bra överblick över din ekonomi och vilken bostadsrätt du har råd att köpa. Med lånelöftet i handen kan jakten på drömlägenheten börja!">

 
                    </Textsection>
                    <Textsection header="" header2="Prisjämförelse"  paragraph="Det kan vara en god idé att göra en snabb undersökning för att ta reda på vad liknande bostadsrätter i området sålts för. Det finns information om slutpriser på nätet som t.ex. hemnet.se eller booli.se som kan ge en indikation över hur marknaden ser ut för just ”din” lägenhet.">

 
                    </Textsection>
                    <Textsection header="" header2="Öppen budgivning"  paragraph="Gbgfast tillämpar öppen budgivning, vilket innebär att mäklaren löpande redovisar buden till både säljaren och alla budgivarna. Budgivarna har då har möjligheten att bjuda över varandra. Öppen budgivningslista innebär att den slutgiltiga köparen och säljaren får ta del av den lista där buden noterats under budgivningsprocessen efter avslutad affär.">

 
                    </Textsection>
                    <Textsection header="" header2="Vad är en bostadsrättsförening?"  paragraph="En bostadsrättsförening är en ekonomisk förening utan vinstsyfte, vars medlemmar äger fastighetens lägenheter. Föreningen måste ha minst tre medlemmar och minst tre lägenheter eller lokaler. Du som bor i bostadsrätt blir alltså medlem i bostadsrättsföreningen och kan engagera dig i bostadsrättsföreningens styrelse och få inflytande över bostadsrättsföreningens ekonomi och fastighetens skick.">
 
                    </Textsection>´
                    <Textsection header="" header2="Äkta eller oäkta förening?"  paragraph="En bostadsrättsförening kan vara en äkta eller oäkta förening. Det vanligaste är äkta bostadsrättsföreningar som har minst 60 procent av sin inkomst som från s.k. kallad kvalificerad verksamhet dvs har 60 % av intäkter från de egna medlemmarna. En oäkta förening har alltså mer än 40% av sina intäkter från andra än sina medlemmar t.ex. uthyrning av affärslokal.">
                        <p className="fs-5">Som köpare är det viktigt att du känner till om föreningen anses vara äkta eller oäkta då detta påverkar de skattetrycket. En oäkta förening måste betala mer i skatt än en äkta, dessutom kan bostadsrättsägaren bli förmånsbeskattad. Det är också olika skattesats vid beräkning av reavinst vid försäljning.</p>
 
                    </Textsection>
                    <Textsection header="" header2="Hur mycket kostar det att bo i lägenhet?"  paragraph="Vad det kostar att bo i lägenhet beror på lägenhetens månadsavgift och avgifter till föreningen. Andra eventuella kostnader som  uppvärmning och renoveringar kan förekomma.">
 
                    </Textsection>
                    <Textsection header="" header2="Avgift"  paragraph="Varje månad, kvartal eller år, betalar du som bostadsrättsägare in en avgift till bostadsrättsföreningen. Avgiften är tänkt till att täcka kostnaderna för de löpande driftskostnaderna som t.ex. värme, vatten, sopor, fastighetsförsäkring och fastighetsskatt. Ibland kan t.ex. även TV- och bredbandsavgift ingå i månadsavgiften och ibland betalar man för vatten och värme separat utöver månadsavgiften. Fråga gärna mäklaren vad som ingår i avgiften.
">                  <p className="fs-5"> 
Om du vill jämföra olika bostäders avgift är det bra att räkna ut avgiften per kvadratmeter, detta står oftast i årsredovisningen för bostadsrättsföreningen.</p>
 
                    </Textsection>
                    <Textsection header="" header2="Underhåll och renoveringar"  paragraph="Det kan vara bra att se över gjorda och framtida underhåll och renoveringar då detta kan påverka bostadsrättsföreningens ekonomi och avgiften för bostadsrätten. Du bör särskilt se över planerade stambyten. Ett stambyte innebär att man byter rör och ledningar som transporterar avlopps- och dricksvatten i huset och är ofta en kostsam åtgärd och som kan ta lång tid att genomföra.">

 
                    </Textsection>
                    <Textsection header="" header2="Vem äger marken?"  paragraph="Ta reda på om det är bostadsrättsföreningen eller någon annan som äger marken som fastigheten står på. Om det är en tomträtt innebär det att kommunen äger marken och ägaren av huset, dvs bostadsrättsföreningen, betalar en avgift till kommunen, avgiften kallas för tomträttsavgäld.">
                        <p className="fs-5">Om ditt nya boende står på en tomträtt är det viktigt att se över avtal och friköpspris, avgiften för en tomträttsavgäld kan nämligen variera kraftigt mellan olika platser. Om avtalet snart går ut finns det också risk att avgiften kan komma att höjas kraftigt när ett nytt avtal börjar gälla.</p>
                    </Textsection>
                    <Textsection header="" header2="Pantsättning"  paragraph="Om du tar ett lån för att finansiera din bostadsrätt, och använder din bostad som säkerhet blir bostadsrätten pantsatt. Det innebär att det sker en pantsättning mellan dig och långivaren. De flesta föreningar tar en avgift på 1% av aktuellt prisbasbelopp.">

 
                    </Textsection>
                    <Textsection header="" header2="Måste jag besiktiga lägenheten?"  paragraph="Utgångspunkten när du köper en lägenhet är att du köper i det skick den befinner sig i på kontraktsdagen. Som köpare är du själv ansvarig att undersöka bostadsrätten före köp, vad som kallas för undersökningsplikt. Därför rekommenderar vi på Gbgfast att man som köpare låter göra en noggrann besiktning innan köp."> 
                    </Textsection>
                    <Textsection header="" header2="Undersökningsplikt"  paragraph="Som köpare har du undersökningsplikt, vilket innebär att du är skyldig att undersöka bostadsrätten noga. Säljaren har inte ansvar för fel som är möjliga att upptäcka vid en undersökning eller som kan förväntas med hänsyn till pris, ålder, och användning."> 
                    </Textsection>
                    <Textsection header="" header2="Dolda fel"  paragraph="Det är säljaren som är ansvarig för dolda fel i två år efter överlåtelsen för bostadsrätter. Dolda fel är större fel eller brister som inte kunnat upptäckas av vare sig säljare, köpare eller besiktningsman. Felet ska inte heller kunna vara förväntat för bostadens ålder, pris eller skick."> 
                    <h2 className="text-center">Välkommen till Gbg Fast!</h2>
                    </Textsection>
                </Contentblock>
                <Contentblock>
                   
                </Contentblock>                
                </div>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>

            </div>
        )
    }
}