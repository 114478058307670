import React, { Component } from 'react'

import Header from "../components/header";
import Shop from '../components/shop';
import Contentblock from '../components/contentblock';
import Footer from '../components/footer';
import Fullvid from '../components/Fullvid';
export default class listings extends Component {
    componentDidMount() {
        document.title = 'Till salu';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid height="40vh" header="Våra objekt till salu" arrow={false} button={false}></Fullvid>
                <Contentblock>
                    <Shop></Shop>
                </Contentblock>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>

            </div>
        )
    }
}