




export default function ColoredLine (props) {

    return (
        <div style={{width:"100%", backgroundColor:props.color,height:"1px"}}></div>
    )

}