import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';

import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Form from '../components/form';
import Footer from '../components/footer';
import { Checkbox } from 'antd';
export default class spanien extends Component {
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/spanien.mp4" brightness="brightness(60%)"  header="Spanien" text="Se det senaste bygget och investera i nyproduktion" button={false}></Fullvid>
  
                <div id="content1"></div>
                    
                <div className="mt-5" >

                <div className="container text-center">
                        <h2>Kolla in vår partner <a href="https://europahusrealestate.com/se/"><span style={{color:"#f9b361", textDecoration:"underline"}}>Europahus</span></a></h2>
                    
                    <img alt=""href="https://europahusrealestate.com/se/"src="/img/europahus.png"></img>
                </div>

                </div>
                <Contentblock>

                    <div className="text-center">
                    
                    <Textsection alignment2="text-center" header="Aktuella projekt" paragraph="Kolla in våra senaste projekt">                  
             
                    </Textsection>
                    </div>
                    <div className="container-xl mt-3 mb-5">



              <h1 className="mt-5 text-center">Slutsålt</h1>
              </div>
              <div id="form" className="container p-5" style={{backgroundColor: "#fef3e4"}}>

                    <Form>
                        <Textsection marginTop="0" alignment2="center" header="Intresserad av Spanien?" paragraph="Få kontakt med en mäklare som är expert på Spanien och
    
    boka en kostnadsfri rådgivning">
     
                        </Textsection>                        
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Adress*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Stad*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Antal kvadratmeter*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Namn*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Mobilnummer*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Email*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="fname" name="firstname" placeholder="Meddelande"/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-75">
                                
                                <Checkbox >Jag samtycker till behandling av mina personuppgifter enligt vår personuppgiftspolicy</Checkbox>                                
    
                                </div>
                            </div>
    
    
    
    
                    </Form>
                  </div>
                 </Contentblock>
                 
                <Contentblock>
                    
                    <Footer></Footer>
                </Contentblock>
            </div>

        )
    }
}