import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';
import {Link} from "react-router-dom";

import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';
import ColoredLine from '../components/coloredline';
export default class team extends Component {
  
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid arrow={true} button={false} target={{hash:"#content1"}} margin="-11rem" height="80vh" img="/img/nykontor.jpeg" brightness="brightness(65%)"displayImg={true} header="Vilka är Gbgfast" buttontext="KOLLA IN VÅRT GRYMMA TEAM" ></Fullvid>
                <div id="content1">
                <Contentblock>
                    <Textsection header="Våra fastighetsmäklare" paragraph="Absolut bäst betalt med Sveriges unikaste Mäklartjänst. Välj en av våra fastighetsmäklare som alltid gör sitt yttersta för att sälja din bostad enkelt och med maximal ekonomisk utväxling!"></Textsection>
                    
                </Contentblock>
                </div>
                <div className="container-sm">
                <div className="row mb-4">
          <div className="col-md-4">
          <Link to="/leif">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Leif</p>
              </div>
              <img alt=""src="./img/leif.jpg" />
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">VD | Reg. Fastighetsmäklare</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Leif Andersson</h2> 
            <h3 className="mt-2">leif.andersson@gbgfast.se</h3>
            <h3 className="">+46 (0)707-71 04 23</h3>
 
            </div>
          </div>
          
          </Link>
          <a href="https://www.instagram.com/leif_andersson_gbgfast/" className='mb-2'><img width="20px" alt="instagram" src="/img/instagram.png" ></img></a>

          </div>

        <div className="col-md-4">
        <Link to="/bo">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Bosse</p>
              </div>
              <img alt=""src="./img/bosse profil.jpg" />
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">Marknadschef | Mäklarassistent</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Bo Andersson</h2>
            <h3 className="mt-2">bo.andersson@gbgfast.se</h3>
            <h3 className="">+46 (0)708-78 79 66</h3>
            </div>
          </div>
        </Link>
        <a href="https://www.instagram.com/bosse_gbgfast_/" className='mb-2'><img width="20px" alt="instagram" src="/img/instagram.png" ></img></a>

        </div>

        <div className="col-md-4">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Lottie</p>
              </div>
              <img alt=""src="./img/lottieprofil4.png" />
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">Reg. Fastighetsmäklare</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Lottie Norén</h2>
            <h3 className="mt-2">lottie.noren@gbgfast.se</h3>
            <h3 className="">+46 (0)721-65 68 16</h3>
            </div>
          </div>

        </div>
        
        {/* 
        <div className="col-md-4">
        <Link to="/michael">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Michael</p>
              </div>
              <img alt=""src="./img/michaelny.jpg"/>
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">REG. FASTIGHETSMÄKLARE</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Michael Jungsén</h2>
            <h3 className="mt-2">michael.jungsen@gbgfast.se</h3>
            <h3 className="">+46 (0)707-16 10 70</h3>
            </div>
          </div>
        </Link>
        </div>
        */}
        </div> 

        <div className="row">

        <div className="col-md-4 mb-5">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Maximilian</p>
              </div>
              <img alt=""src="./img/Maxi profil.jpg"/>
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">Mäklarassistent</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Maximilian Haglid Andersson</h2>
            <h3 className="mt-2">maximilian.andersson@gbgfast.se</h3>
            <h3 className="">+46 (0)721 81 85 10</h3>
            </div>
          </div>
          <a href="https://www.instagram.com/maximilian_de_musset/" className='mb-2'><img width="20px" alt="instagram" src="/img/instagram.png" ></img></a>

        </div>
        <div className="col-md-4">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Charbel</p>
              </div>
              <img alt=""src="./img/Charbel profil.jpg"/>
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">Customer Service | Borådgivning</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Charbel Younes</h2>
            <h3 className="mt-2">charbel.younes@gbgfast.se</h3>
            <h3 className="">+46 (0)708-78 62 86</h3>
            </div>
          </div>
          <a href="https://www.instagram.com/charbelfrancisyounes/" className='mb-2'><img width="20px" alt="instagram" src="/img/instagram.png" ></img></a>

        </div>

        {/* 
        <div className="col-md-4">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Maxilian</p>
              </div>
              <img alt=""src="./img/Harun Profil.jpg"/>
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">Customer Service | Borådgivning</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Michael Jungsén</h2>
            <h3 className="mt-2">michael.jungsen@gbgfast.se</h3>
            <h3 className="">+46 (0)707-16 10 70</h3>
            </div>
          </div>
        </div>
        */}
       
        <div className="col-md-4 mb-4">
          <div className="teammem">
            <div className="teamimg">
              <div className="semer">
                <p>Mer om Moltas</p>
              </div>
              <img alt=""src="./img/Moltas profil.jpg"/>
            </div>
            <div className="meminfo">
            <p className="m-0 p-0">Webbdesign | Utvecklare</p>
            <ColoredLine color="#f9b361"></ColoredLine>
            <h2 className="">Moltas Thörnblom</h2>
            <h3 className="mt-2">moltas@gbgfast.se</h3>
            <h3 className="">+46 (0)721-99 90 40</h3>
            </div>
          </div>

        </div>
        
      </div>
      </div>

                <Contentblock>
                    <Footer></Footer>
                </Contentblock>
            </div>

        )
    }
}