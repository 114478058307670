import React, { Component } from 'react'
import {Link} from "react-router-dom";

export default class Objekt extends Component {
  getNestedObject = (nestedObj, pathArr) => {


    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
      

}
  eval2(data) {
    
    data = this.getNestedObject(this.props.data, data)
    if (data === undefined) {
      return ""
    }
    return data

  }
    render() {
        

        return (
          <Link to={`/objekt/${ this.props.data["id"] }`} style={this.props.isSold && {pointerEvents: "none"}}>
 
            <div className="fastighet"  style={{position:"relative"}}>
            {!this.props.isSold && this.props.data["bidding"]["isActive"] &&
            <div style={{position:"absolute", top:"20px",right:"20px", width:"200px", height:"65px", backgroundColor:"#686464", zIndex:"3", padding:"1rem", color:"white", boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}> 
              <p className="fs-5 m-0 mb-2" >BUDGIVNING PÅGÅR</p>

          </div>
            
            }
              
              <div className="fastighetimg" >
                  
                  <img alt="houseimg" src={"/deals/img/" + this.props.data["id"].replace("..", "") + "/main.jpg"} />
                  {this.props.data.publishingInformation.saleStatus === "ENUMS_SALE_STATUS_COMING" &&
                    <div className="semer" style={{opacity:"1", backgroundColor:"    background: rgba(0,0,0,0.8)"}}>
                    <p className="fs-4">SNART TILL SALU</p></div>
                  }
              {this.props.isSold &&

              <div className="semer" style={{opacity:"1", backgroundColor:"    background: rgba(0,0,0,0.8)"}}>
                <p className="fs-4">Såld</p></div>
                }
              </div>
              {!this.props.isSold ?
              <div className="fastighetinfo">
              <p >{this.eval2(["location","city"]) + (this.eval2(["location","district"]) ? " / ": "") + this.eval2(["location","district"])}</p>
                <h2 >{this.eval2(["location","streetAddress"])}</h2>
                {this.eval2(["rooms","numberOfRooms"]) ?
                <p className="fs-5">{this.props.data["rooms"]["numberOfRooms"] + " rok " + this.props.data["estate"]["livingArea"] + " kvm " + (this.eval2(["saleInformation", "price"]) ? this.eval2(["saleInformation", "price"]).toLocaleString() + " kr": "")}</p>
              : <p className="fs-5">{(this.eval2(["saleInformation", "price"]) ? this.eval2(["saleInformation", "price"]).toLocaleString() + " kr": "")}</p>

              
              }

                </div> :
              <div className="fastighetinfo">
              
              <p >{this.eval2(["location","city"]) + " / " + this.eval2(["location","district"])}</p>
             
              {this.eval2(["location","streetAddress"]) &&
              <h2 >{this.eval2(["location","streetAddress"])}</h2>
            } 
              {this.eval2(["saleInformation","finalPrice"]) &&
              <p className="fs-4">Pris: {this.props.data["saleInformation"]["finalPrice"].toLocaleString()} kr</p>
            }
            </div>

              }
            </div>
            </Link>


          )
    }
}




