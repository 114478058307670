import React, { Component } from "react";
import { Carousel } from "antd";
import Slide from "react-reveal/Slide";

export default class Reel extends Component {
  render() {
    return (
      <div id="reel" className="container bg-white">
        <div className="row">
          <Carousel autoplay>
            <div className="fastighetimg3">
            <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "1",

                  background:
                    "linear-gradient(0deg, rgba(2,0,36,0.7) 0%, rgba(0,0,0,0.7) 21%, rgba(189,189,189,0) 100%)",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  zIndex: "3",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    marginRight: "1rem",
                    width: "130px",
                    height: "45px",
                    backgroundColor: "#686464",
                    zIndex: "3",
                    borderRadius: "0 0 0 0",
                    padding: "0.4rem",
                    color: "white",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    height: "45px",
                    backgroundColor: "#686464",
                    zIndex: "3",
                    borderRadius: "0 0 0 0",
                    padding: "0.4rem",
                    color: "white",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                </div>
              </div>
              <img
                alt="bild"
                className="spainIm"
                style={{ filter: "brightness(75%)" }}
                src="https://casas-ambiente.com/media/images/properties/o_1cqgngsm3c0518kqgol11a51jol36.jpg"
              />
              <div
                className="fastighetinfo text-start"
                style={{ position: "absolute", bottom: "-20px", left: "20px" , zIndex:"3"}}
              >
                <h3 style={{ color: "white" }}>Alicante</h3>
                <h2 style={{ color: "white" }}>Calpe</h2>
                <p style={{ color: "white" }}>336 M2 ROK 1795000 EUR </p>
              </div>
            </div>
            <div className="fastighetimg3">
            <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "1",

                  background:
                    "linear-gradient(0deg, rgba(2,0,36,0.7) 0%, rgba(0,0,0,0.7) 21%, rgba(189,189,189,0) 100%)",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  zIndex: "3",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    marginRight: "1rem",
                    width: "130px",
                    height: "45px",
                    backgroundColor: "#686464",
                    zIndex: "3",
                    borderRadius: "0 0 0 0",
                    padding: "0.4rem",
                    color: "white",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    height: "45px",
                    backgroundColor: "#686464",
                    zIndex: "3",
                    borderRadius: "0 0 0 0",
                    padding: "0.4rem",
                    color: "white",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                </div>
              </div>
              <img
                alt="bild"
                className="spainIm"
                style={{ filter: "brightness(75%)" }}
                src="https://www.ghcostablanca.com/objetos/temp/source/ghcostablanca/ghcostablanca-propiedades_72cd9cf6bafb1e21a3efdbe57f2a64eb377d700b-source.jpg"
              />
              <div
                className="fastighetinfo text-start"
                style={{ position: "absolute", bottom: "-20px", left: "20px" , zIndex:"3"}}
              >
                <h3 style={{ color: "white" }}>Alicante</h3>
                <h2 style={{ color: "white" }}>Calpe</h2>
                <p style={{ color: "white" }}>12 rok 6500 kvm 20 000 000 kr</p>
              </div>
            </div>
            <div className="fastighetimg3">
            <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "1",

                  background:
                    "linear-gradient(0deg, rgba(2,0,36,0.7) 0%, rgba(0,0,0,0.7) 21%, rgba(189,189,189,0) 100%)",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  zIndex: "3",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    marginRight: "1rem",
                    width: "130px",
                    height: "45px",
                    backgroundColor: "#686464",
                    zIndex: "3",
                    borderRadius: "0 0 0 0",
                    padding: "0.4rem",
                    color: "white",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <p className="fs-5 m-0 mb-2">OFFMARKET</p>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    height: "45px",
                    backgroundColor: "#686464",
                    zIndex: "3",
                    borderRadius: "0 0 0 0",
                    padding: "0.4rem",
                    color: "white",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <p className="fs-5 m-0 mb-2">BIGGEST VALUE</p>
                </div>
              </div>
              <img
                alt="bild"
                className="spainIm"
                style={{ filter: "brightness(75%)" }}
                src="https://casas-ambiente.com/media/images/properties/o_1ec2p95091lo9rcs13tc9k05p23b.jpeg"
              />
              <div
                className="fastighetinfo text-start"
                style={{ position: "absolute", bottom: "-20px", left: "20px", zIndex:"3" }}
              >
                <h3 style={{ color: "white" }}>Alicante</h3>
                <h2 style={{ color: "white" }}>Calpe</h2>
                <p style={{ color: "white" }}>12 rok 6500 kvm 20 000 000 kr</p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}
