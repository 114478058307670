import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Footer from '../components/footer';
import Form from '../components/form';
import Textsection from '../components/textsection';
export default class jobba extends Component {
    componentDidMount() {
        document.title = 'Jobba på gbgfast';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/celeb.mp4" header="VI SÖKER DIG" buttontext="SKICKA DITT CV IDAG"></Fullvid>
                <Contentblock>
                    <Textsection header="JOBBA PÅ GBG FAST" paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."></Textsection>
                    <Form></Form>
                </Contentblock>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>

            </div>
        )
    }
}