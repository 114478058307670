import React, { Component } from 'react'

export default class Modal extends Component {

    render() {
        

        return(
            <div>

            
                <div className="formmodal"  style={this.props.modalVisible ? {display:"block"} : {display:"none"} }>
                    <div className="formmodalcontent"  style={this.props.modalVisible ? {display:"block"} : {display:"none"} }>
                    {this.props.children}
                    </div>
                 </div>
 
             </div>
        )
    }
}

Modal.defaultProps= {
    modalVisible:false, 
}

