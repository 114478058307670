import ColoredLine from "./coloredline"
import Slide from 'react-reveal/Slide';
import React, { Component } from 'react'
import {Link} from "react-router-dom";

export default class Fullvid extends Component {

    render() {
        return (
            <div className="container-md p-0" style={{ overflow: "visible" }}>
            <Slide bottom cascade>
            <div className="row gx-5 mb-5 imggrid5">
            <div className="col order-md-last imggrid5col">
                    <img alt="redo"className="fullimg" src="/img/sprintF.jpg"></img>
                    <Link to={{ hash: "#head" }}>

                    <div className="semer2" onClick={() => this.props.buttonFunc(3)}>
                        <p>Jag vill gärna veta mer!</p>
                    </div>
                    </Link>
                    
                </div>
                <div className="col-md imggrid5col" >
                    <h1>Redo!</h1>
                    <p className="  fs-5">Vi förbereder din försäljning av ditt nuvarande boende. Tjänsten är
                        självklart också avsedd för köpare som inte har något eget boende
                        att sälja men som vill bevaka ett område.</p>
                    <div className="hideLine"><ColoredLine color="#f9b361"></ColoredLine></div>
                    <div className="text-center">
                    <Link to={{ hash: "#head" }}>

                    <input type="submit" onClick={() => this.props.buttonFunc(3)} value="Anmäl dig här" className="btn mt-5" style={{  height: "40px", padding: "0",}} />
                    </Link>

                    </div>

    
                </div>

    
    
    
            </div>
            <div className="row gx-5  mb-5 imggrid5">
                <div className="col imggrid5col">
                    <img alt="modernhouse" className="fullimg imgleft" src="/img/modernhouseF.jpg"></img>
                    <Link to={{ hash: "#head" }}>

                    <div className="semer3 " onClick={() => this.props.buttonFunc(1)}>
                        <p>Jag vill gärna veta mer!</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md imggrid5col">
                    <h1>Etta på bollen!</h1>
                    <p className="  fs-5">Helt kostnadsfritt kan du nu skapa en bevakning och få reda på
                        bostäder före de når den öppna marknaden. Vi säljer mycket på
                        förhand via dessa bevakningar.</p>
                        <div className="hideLine"><ColoredLine color="#f9b361"></ColoredLine></div>
                        <div className="text-center">
                        <Link to={{ hash: "#head" }}>

                    <input type="submit" onClick={() => this.props.buttonFunc(6)} value="Anmäl dig här" className="btn mt-5" style={{height: "40px", padding: "0",}} />
                    </Link>
                    </div>
                </div>
    
    
    
            </div>
            <div className="row gx-5  mb-5 imggrid5">
            <div className="col imggrid5col order-md-last">
                    <img alt="rådgivning"className="fullimg" src="/img/kokF.jpg"></img>
                    <Link to={{ hash: "#head" }}>

                    <div className="semer2" onClick={() => this.props.buttonFunc(2)}>
                        <p>Jag vill gärna veta mer!</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md imggrid5col ">
                    <h1>Aktuell marknad!</h1>
                    <p className="  fs-5">Vår rådgivningstjänst aktuell marknad är en komplett miniutbildning vi levererar kostnadsfritt i din bostad som är baserad på dagsfärsk marknadsinformation, låneregler, trender och allt du
                        behöver veta för att ta rätt beslut.</p>
                        <div className="hideLine"><ColoredLine color="#f9b361"></ColoredLine></div>
                        <div className="text-center">
                        <Link to={{ hash: "#head" }}>

                    <input type="submit" onClick={() => this.props.buttonFunc(2)} value="Anmäl dig här" className="btn mt-5" style={{  height: "40px", padding: "0",}} />
                    </Link>
                    </div>
                </div>

    
            </div>
            <div className="row gx-5  mb-5 imggrid5" id="underhand">
                <div className="col imggrid5col">
                    <img alt="sälj underhand"className="fullimg imgleft" src="/img/bridgeF.jpg"></img>
                    <Link to={{ hash: "#head" }}>

                    <div className="semer3" onClick={() => this.props.buttonFunc(4)}>
                        <p>Jag vill gärna veta mer!</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md imggrid5col">
                    <h1>Sälj i smyg på VIP - Underhand!</h1>
                    <p className="  fs-5">Har du en exklusiv bostad eller behov att sälja utan att någon vet så
                        listar vi den underhand med vår tjänst Sälj i Smyg. Matchningen
                        sker löpande mot utvalda intressenter i hela Europa.</p>
                        <div className="hideLine"><ColoredLine color="#f9b361"></ColoredLine></div>
                        <div className="text-center">
                        <Link to={{ hash: "#head" }}>

                    <input type="submit"  onClick={() => this.props.buttonFunc(4)} value="Anmäl dig här" className="btn mt-5" style={{  height: "40px", padding: "0",}} />
                    </Link>
                    </div>
                </div>
    
    
            </div>
            <div className="row gx-5  mb-5 imggrid5">
            <div className="col imggrid5col order-md-last">
                    <img alt="värdering av bostad"className="fullimg" src="/img/kok2F.jpg"></img>
                    <Link to={{ hash: "#head" }}>

                    <div className="semer2" onClick={() => this.props.buttonFunc(1)}>
                        <p>Jag vill gärna veta mer!</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md imggrid5col">
                    <h1>Värde och Sälja!</h1>
                    <p className="  fs-5">Gbgfast har skapat branschens modernaste verktyg, utanför traditionella ramverk med sikte på absolut bäst betalt för din bostad. Vill du veta hur vi gör kan det vara klokt att kontakta oss!</p>
                        <div className="hideLine"><ColoredLine color="#f9b361"></ColoredLine></div>
                        <div className="text-center">
                        <Link to={{ hash: "#head" }}>

                    <input type="submit" onClick={() => this.props.buttonFunc(1)} value="Anmäl dig här" className="btn mt-5" style={{  height: "40px", padding: "0",}} />
                        </Link>
                    </div>
                </div>
  
    
            </div>
            <div className="row gx-5  mb-5 imggrid5">
                <div className="col imggrid5col">
                    <img alt="värdebevakning"className="fullimg imgleft" src="/img/DoorF.jpg"></img>
                    <Link to={{ hash: "#head" }}>
                    <div className="semer3" onClick={() => this.props.buttonFunc(5)}>
                        <p>Jag vill gärna veta mer!</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md imggrid5col">
                    <h1>Värde nu och sedan!</h1>
                    <p className="  fs-5">För dig som vill ha koll på utveckling av hur din bostad förändras i
                        värde över tid har vi en praktisk tjänst, Tanken är att du som vill sälja
                        i framtiden skall kunna uppnå absolut bäst timing.</p>
                        <div className="hideLine"><ColoredLine color="#f9b361"></ColoredLine></div>
                        <div className="text-center">
                        <Link to={{ hash: "#head" }}>

                    <input type="submit" onClick={() => this.props.buttonFunc(5)} value="Anmäl dig här" className="btn mt-5" style={{  height: "40px", padding: "0",}} />
                    </Link>
                    </div>
                </div>
    
    
            </div>
            </Slide>
        </div>
        )
    }
    
}