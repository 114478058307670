import React, { Component } from 'react'
import { message } from 'antd';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

export default class FormSpanien extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)

}
success = () => {
  message.success({
    content: 'Tack för din anmälan, vi återkommer så fort vi kan',
    className: 'message',
    duration: 5,
    
  });
  
};
 error = () => {
  message.error({
    content: 'Din anmälan kunde inte skickas. Prova igen',
    className: 'message',
    duration: 5,
    
  });
};
  handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.target);
    const array = {}
    const array3 = {}

    for (let [key, value] of data.entries()) {
      array[key] = value
    }
    

     if(this.props.title) {
      ReactGA.event({
        category: "formSubmit",
        action: "Submit",
        label: this.props.title, // optional
      });
      ReactPixel.track(("formSubmit: " + this.props.title));

      
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let array2 = {
        "blocks": [
          {
            "type": "header",
            "text": {
              "type": "plain_text",
              "text": this.props.title + " Hemsida",
              "emoji": true
            }
          },
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "Name: " + array["consumerFamilyName"] + " \n Email: " + array["consumerEmail"] + "\n Phone: " + array["consumerMsisdn"]+ "\n ID Spanienobjekt: "+ this.props.id+ "\n  Date: " + date + "\n Message: " + array["taskDescription"] 
            }
          },
          {
            "type": "divider"
          }
        ]
      }
      console.log(array2)
      this.props.modalToggleFinish() 

      fetch('https://hooks.slack.com/services/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', {
        method: 'POST',
        headers: {
          
          
        },
        body: JSON.stringify(array2)
      })
      .then(response => {
          
      })

    
      .catch(error => {
        console.log(error)
        

      })

    }
     
  };  

    render() {
        

        return(
      <div className=" container-xl formcontainer " >
        <form onSubmit={this.handleSubmit} >
          {/*https://api1.legacyestate.se1.quedro.com/data/storeeataskfromtemplate.php*/}
          {this.props.children}

          <br />
          <div >
            <input type="submit" className="btn btn-primary " defaultValue="Submit" />
          </div>
        </form>
      </div>

        )
    }
}



