import React, { Component } from 'react'

import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Fullvid from "../components/Fullvid";
import Textsection from '../components/textsection';

import Footer from '../components/footer';
import FormModal from '../components/formmodal';
import Valuation from '../components/forms/valuation';
import Imagegrid3 from '../components/imagegrid3';
import Currentmarket from '../components/forms/currentmarket';
import Redo from '../components/forms/redo';
import Underhand from '../components/forms/underhand';
import Valuewatch from '../components/forms/valuewatch';
import First from '../components/forms/first';
export default class landing extends Component {
    constructor(props) {
        super(props);
        this.handleToggle = React.createRef();
        this.state = {
            formUsed: 2,
        }
      }

    buttonClick (form = 1) {
        this.handleToggle.current.modalToggle()
        this.setState({formUsed: form})

    }
    componentDidMount() {
        document.title = 'Gbgfast';

        const hash = this.props.history.location.hash
        // Check if there is a hash and if an element with that id exists
        const el = hash && document.getElementById(hash.substr(1))
        if (el) {    
            el.scrollIntoView({behavior: "smooth"})
        }
        
    }
    render() {

        return(
            <div id="head">
                
                <FormModal ref={this.handleToggle}>
                    
                    {(() => {
                        switch (this.state.formUsed) {
                        case 1:   return <Valuation></Valuation>;
                        case 2: return <Currentmarket></Currentmarket>;
                        case 3:  return <Redo></Redo>;
                        case 4: return <Underhand></Underhand>
                        case 5: return <Valuewatch></Valuewatch>
                        case 6: return <First></First>
                        default:      return "Ingen formulär laddades";
                        }
                    })()}
                </FormModal>
                <Header background=""></Header>
                <Fullvid header="Vill du sälja?" brightness="brightness(80%)" buttontext="BOKA FRI VÄRDERING" buttonFunc={() => this.buttonClick(1)}></Fullvid>
                <div id="content1">
                <Contentblock>
                    <Textsection></Textsection>
                    
                </Contentblock>
                {/*
                    <div className="container">
                    <Tabbar></Tabbar>
                    </div>
                */}
                <Imagegrid3 buttonFunc={this.buttonClick.bind(this)}></Imagegrid3>
                </div>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>
 
                
                
            </div>
        )
    }
}