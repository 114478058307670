import React, { Component } from "react";

import Header from "../components/header";
import Fullvid from "../components/Fullvid";
import Contentblock from "../components/contentblock";
import Footer from "../components/footer";
import Card from "../components/card";
import FormModal from "../components/formmodal";
import Imagegrid4 from "../components/imagegrid4";
import { Link } from "react-router-dom";
import ColoredLine from "../components/coloredline";
import Tabs from "../components/tabs";
import Spanienform from "../components/forms/spanien";
import Contact from "../components/forms/contact";
import Map2 from "../components/map2";
import Bookviewing from "../components/forms/bookviewing";
import Slideshow from "../components/slideshow";
import {
  GiHighGrass,
  GiFamilyHouse,
  GiPersonInBed,
  GiBathtub,
  GiPriceTag,
} from "react-icons/gi";
export default class villa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      modalVisible: false,
      windowWidth: window.innerWidth,
      showingSlotId: "",
      showingId: "",
    };
    this.getNestedObject = this.getNestedObject.bind(this);
    this.handleToggle = React.createRef();
    this.openShow = React.createRef();
    this.buttonClick = this.buttonClick.bind(this);
    this.showFull = React.createRef();
    
  }

  getMainImage() {
    var url = "";
    this.state.deal["images"]["image"].map((img) =>
      img["id"] === "1" ? (url = img["url"]) : null
    );
    return url;
  }
  getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  getValue(data = "", title = "", suffix = "", prefix = "") {
    data = this.getNestedObject(this.state.deal, data);

    if (data) {
      return (
        <p className="fs-5   mb-1">
          <span className="fs-5 fw-bolder m-0">
            {title ? title + ":" : null}{" "}
          </span>
          {prefix + data.toLocaleString() + " " + suffix}
          <br />{" "}
        </p>
      );
    }
  }

  eval2(data) {
    data = this.getNestedObject(this.state.deal, data);
    return data;
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  parseDate(date) {
    var d = new Date(date);
    var clock =
      (d.getHours() < 10 ? "0" : "") +
      d.getHours() +
      ":" +
      (d.getMinutes() < 10 ? "0" : "") +
      d.getMinutes();
    console.log(clock);
    d = d.toISOString().substring(0, 10) + " " + clock;
    return d;
  }
  async getData() {
    var res = await fetch("/premiumvilla.json", {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    });
    var data = await res.json();

    var id = this.props.match.params.objektId;
    var deal = {};
    data.property.map(function (item, i) {
      if (item["id"] == id) {
        deal = item;
      }
    });
    console.log(deal);
    return this.setState({ deal: deal });
  }
  buttonClick(slotId, Id) {
    this.handleToggle.current.modalToggle();
    this.setState({ showingSlotId: slotId, showingId: Id });
  }
  openSlideShow(index) {
    this.openShow.current.showToggleOn(index);
  }
  openSlideShowFP() {
    this.openShow.current.showToogleFP();
  }
  openFullShow() {
    this.showFull.current.showFullBtn();
    this.openShow.current.showToggleFP();
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    this.getData();
  }

  render() {
    let _this = this;

    return (
      <div id="head">
        {this.state.deal && (
          <div>
            <FormModal ref={this.handleToggle}>
              <Spanienform id={this.state.deal["id"]} title={"Intresse av objekt: " + this.state.deal["id"]}></Spanienform>
            </FormModal>
            <Slideshow
              id={this.state.deal["id"]}
              ref={this.openShow}
              images={this.state.deal["images"]["image"]}
            ></Slideshow>

            <Header></Header>

            <Fullvid
              height="80vh"
              arrow={false}
              img={this.state.deal["images"]["image"][0].url}
              header={this.state.deal["town"]}
              button={false}
              text={this.state.deal["province"]}
              displayImg={true}
            >
              <div className="navbarObjekt">
                <div
                  className="navbarObjektText "
                  style={{ letterSpacing: "3px" }}
                >
                  <a
                    href="#content1"
                    style={{ marginLeft: "2vw", marginRight: "2vw" }}
                  >
                    Översikt
                  </a>
                  <a
                    href="#bilder"
                    style={{ marginLeft: "2vw", marginRight: "2vw" }}
                  >
                    Bilder
                  </a>
                </div>
              </div>
            </Fullvid>

            <div id="content1" className="pt-4">
              <div className="container text-center">
                <div className="row">
                  <div className="col">
                    <GiHighGrass size={30}></GiHighGrass>
                    <h2 className="p-0 m-0">
                      {this.state.deal["surface_area"]["plot"]} M2
                    </h2>
                    <p>Plotarea</p>
                  </div>
                  <div className="col">
                    <GiFamilyHouse size={30}></GiFamilyHouse>
                    <h2 className="p-0 m-0">
                      {this.state.deal["surface_area"]["built"]} M2
                    </h2>

                    <p>Constructed Area</p>
                  </div>
                  <div className="col">
                    <GiPersonInBed size={30}></GiPersonInBed>
                    <h2 className="p-0 m-0">{this.state.deal["beds"]}</h2>

                    <p>Beds</p>
                  </div>
                  <div className="col">
                    <GiBathtub size={30}></GiBathtub>
                    <h2 className="p-0 m-0">{this.state.deal["baths"]}</h2>

                    <p>Baths</p>
                  </div>
                  <div className="col">
                    <GiPriceTag size={30}></GiPriceTag>
                    <h2 className="p-0 m-0">
                      {this.state.deal["price"].toLocaleString()} EUR
                    </h2>

                    <p>Price</p>
                  </div>
                </div>
              </div>
              <div className="container-xl" style={{ maxWidth: "1600px" }}>
                <div className="row gx-5 mk-5">
                  <div className="col-md px-4 mt-4">
                    <div>
                      <p className="fs-5   m-0 pb-1 text-uppercase font-serif">
                        {this.state.deal["province"] +
                          " / " +
                          this.state.deal["town"]}
                      </p>
                      <ColoredLine color="#f9b361"></ColoredLine>

                      <h2 className=" mt-3">
                        {this.state.deal["price"].toLocaleString()} EUR
                      </h2>
                      <Tabs data={this.state.deal}></Tabs>
                      {/*
                      <p className="fs-5   m-0 pb-1 font-serif text-uppercase">snabbfakta</p>
                      <ColoredLine color="#f9b361"></ColoredLine>
                      <div className="container-fluid p-0 mt-3 objectFacts">
                        <div className="row">
                        {this.getValue(['pool'], "Pools", "")}
                        {this.getValue(['baths'], "Baths", "")}
                        {this.state.deal.new_build && 

                        <p className="fs-5   mb-1"><span className="fs-5 fw-bolder m-0">Newly built:</span>Yes<br />                  </p>
                        
                        
                        }

                         

                        </div>





                      </div>*/}
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="mt-4">
                      <p
                        className="fs-5 font-serif   mt-3 m-0 pb-1 "
                        style={{ letterSpacing: "1px" }}
                      >
                        {this.state.deal.desc.en}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-5 text-center">
              <Link to={{ hash: "#head" }}>
                <input
                  type="submit"
                  onClick={() => this.buttonClick()}
                  value="Find out more!"
                  className="btn mt-5"
                  style={{ height: "60px", padding: "0", fontWeight: 600 }}
                />
              </Link>
              </div>
              <Contentblock>
                
              </Contentblock>
            </div>
            <div id="bilder">
              {this.eval2(["video_url"]) && (
                <div className="container-lg text-center">
                  <iframe
                    className="objektVideo"
                    src={
                      "https://www.youtube.com/embed/" +
                      this.eval2(["video_url"]).split("v=")[1]
                    }
                  ></iframe>
                </div>
              )}
              <Imagegrid4
                ref={this.showFull}
                buttonFunc={this.openSlideShow.bind(this)}
                id={this.state.deal["id"]}
                images={this.state.deal["images"]["image"]}
              ></Imagegrid4>
              {/*<Contentblock>
                    <div>
                        <img className="props3" style={{filter: 'brightness(100%)'}} src="/img/planritning.jpg" />
                    </div>
                    
                </Contentblock>*/}
            </div>
            {this.state.deal.location && (
              <div className="container">
                <Map2
                  lat={this.state.deal.location.latitude}
                  lng={this.state.deal.location.longitude}
                ></Map2>
              </div>
            )}

            <Contentblock>
              <Footer></Footer>
            </Contentblock>
          </div>
        )}
      </div>
    );
  }
}
