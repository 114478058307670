import React from 'react'
import Form from '../form'
import Textsection from '../textsection'

export default function Underhand (props) {
  
/*Har du en exklusiv bostad eller behov att sälja utan att någon vet så listar vi den underhand. Matchning sker löpande mot utvalda intressenter i hela Europa.*/
 
        return(
                    <Form formId="567" title="Underhand - Säljare (off market)">
                        <Textsection textCont=""color="white" marginTop="0" alignment2="center" header="Vill du sälja i smyg?" paragraph="">
     
                        </Textsection>
                        <div className="row">
                                <div className="col">
                                <input type="text" id="consumerFirstName" name="consumerFirstName" placeholder="Förnamn*" required/>
                                </div>
                                <div className="col">
                                <input type="text" id="consumerFamilyName" name="consumerFamilyName" placeholder="Efternamn*" required/>
                                </div>
                            </div>
                        
                        <div className="row">
                                <div className="col">
                                <input type="text" id="consumerMsisdn" name="consumerMsisdn" placeholder="Mobilnummer*" required/>
                                </div>
                                <div className="col">
                                <input type="text" id="consumerEmail" name="consumerEmail" placeholder="Email*" required/>
                                </div>
                            </div>  
                        
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerCity" name="consumerCity" placeholder="Stad*" required/>
                                </div>
                            </div>                       
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerStreet" name="consumerStreet" placeholder="Adress*" required/>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-75">
                                
                                <input type="checkbox" required></input>                  
                                <span style={{paddingLeft:"5px", color:"white"}}>Jag samtycker till behandling av mina personuppgifter enligt vår <a href="/img/Gbgfast Personuppgiftspolicy (GDPR).pdf" style={{textDecoration:"underline"}}>personuppgiftspolicy</a>   </span>                                </div>
                            </div>
    
    
    
    
                    </Form>

        )
    
}




