import React from 'react'
import Form2 from '../form2'
import Textsection from '../textsection'

export default function Bookviewing (props) {
  

 
        return(
                    <Form2 modalToggleFinish={props.modalToggleFinish} >
                        <Textsection  textCont="" color="white" marginTop="0" alignment2="center" header={"Anmälan visning" } paragraph="">
     
                        </Textsection>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerFirstName" name="consumerFirstName" placeholder="Förnamn*" required/>
                                </div>
                            </div>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerFamilyName" name="consumerFamilyName" placeholder="Efternamn*" required/>
                                </div>
                        </div>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerMsisdn" name="consumerMsisdn" placeholder="Mobilnummer*" required/>
                                </div>
                            </div>  
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerEmail" name="consumerEmail" placeholder="Email*" required/>
                                </div>
                        </div>
                        <input type="hidden" id="guid" name="guid" value={props.dealId}/> 
                        <input type="hidden" id="showingId" name="showingId" value={props.showingId}/> 
                        {props.showingSlotId && 
                        <input type="hidden" id="showingSlotId" name="showingSlotId" value={props.showingSlotId}/> 
                        }


                            


                            <div className="row mt-3">
                                <div className="col-75">
                                
                                <input type="checkbox" required></input>                  
                                <span style={{paddingLeft:"5px", color:"white"}}>Jag samtycker till behandling av mina personuppgifter enligt vår <a href="/img/Gbgfast Personuppgiftspolicy (GDPR).pdf" style={{textDecoration:"underline"}}>personuppgiftspolicy</a>   </span>           
                                </div>
                            </div>
    
    
    
    
                    </Form2>

        )
    
}

