import React from 'react'
import Form from '../form'
import Textsection from '../textsection'

export default function Akademi (props) {
  

 
        return(
                    <Form formId="579" title="Akademi" modalToggleFinish={props.modalToggleFinish} >
                        <Textsection  textCont=""  marginTop="0" alignment2="center" header={"Anmälan till akademi" } paragraph="">
     
                        </Textsection>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerFirstName" name="consumerFirstName" placeholder="Förnamn*" required/>
                                </div>
                            </div>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerFamilyName" name="consumerFamilyName" placeholder="Efternamn*" required/>
                                </div>
                        </div>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerMsisdn" name="consumerMsisdn" placeholder="Mobilnummer*" required/>
                                </div>
                            </div>  
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerEmail" name="consumerEmail" placeholder="Email*" required/>
                                </div>
                        </div>
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerCity" name="consumerCity" placeholder="Stad*" required/>
                                </div>
                            </div>                       
                        <div className="row">
                                <div className="col-75">
                                <input type="text" id="consumerStreet" name="consumerStreet" placeholder="Adress*" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-75">
                                <input type="text" id="taskDescription" name="taskDescription" placeholder="Varför vill du bli mäklare?" required/>
                                </div>
                            </div>

                            


                            <div className="row mt-3">
                                <div className="col-75">
                                
                                <input type="checkbox" required></input>                  
                                <span style={{paddingLeft:"5px"}}>Jag samtycker till behandling av mina personuppgifter enligt vår <a href="/img/Gbgfast Personuppgiftspolicy (GDPR).pdf" style={{textDecoration:"underline"}}>personuppgiftspolicy</a>   </span>           
                                </div>
                            </div>
    
    
    
    
                    </Form>

        )
    
}

