import React, { Component } from 'react'
import { message } from 'antd';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

export default class Form2 extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)

}
success = () => {
  message.success({
    content: 'Tack för din anmälan, vi återkommer så fort vi kan',
    className: 'message',
    duration: 5,
    
  });
  
};
 error = () => {
  message.error({
    content: 'Din anmälan kunde inte skickas. Prova igen',
    className: 'message',
    duration: 5,
    
  });
};
  handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.target);
    const array = {}
    for (let [key, value] of data.entries()) {
      array[key] = value
    }
    console.log(array)
    fetch('https://www.gbgfast.se/webhooks/bookviewing.php', {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        'Accept': 'application/json'

        
      },
      body: JSON.stringify(array)
     }).then(() => {
      if(this.props.modalToggleFinish) {
         this.props.modalToggleFinish() 
        }
        ReactGA.event({
          category: "formSubmit",
          action: "Submit",
          label: "Boka visning", // optional
        });
        ReactPixel.track("formSubmit: Boka visning");

       this.success()

     })
     .catch(error => {
       console.log(error)
       if(this.props.modalToggleFinish) {
        this.props.modalToggleFinish() 
       }

       this.error()

     })
     
  };  

    render() {
        

        return(
      <div className=" container-xl formcontainer " >
        <form onSubmit={this.handleSubmit} >
          {/*https://api1.legacyestate.se1.quedro.com/data/storeeataskfromtemplate.php*/}
          {this.props.children}

          <br />
          <div >
            <input type="submit" className="btn btn-primary " defaultValue="Submit" />
          </div>
        </form>
      </div>

        )
    }
}



