import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';

import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';
import FormModal from '../components/formmodal';
import Valuation from '../components/forms/valuation';
import Currentmarket from '../components/forms/currentmarket';
import Redo from '../components/forms/redo';
import Underhand from '../components/forms/underhand';
import Valuewatch from '../components/forms/valuewatch';
import Imagegrid3 from '../components/imagegrid3';
import First from '../components/forms/first';
export default class sell extends Component {
    constructor(props) {
        super(props);
        this.handleToggle = React.createRef();

        this.state = {
            modalVisible: false,
            formUsed: 2,


        };
    }

    buttonClick(form = 1) {
        this.handleToggle.current.modalToggle()
        this.setState({formUsed: form})
    }
    componentDidMount() {
        document.title = 'Sälj med oss';
      }
    render() {
        return (

            <div id="head">
                <FormModal ref={this.handleToggle}>
                    
                    {(() => {
                        switch (this.state.formUsed) {
                        case 1:   return <Valuation></Valuation>;
                        case 2: return <Currentmarket></Currentmarket>;
                        case 3:  return <Redo></Redo>;
                        case 4: return <Underhand></Underhand>
                        case 5: return <Valuewatch></Valuewatch>
                        case 6: return <First></First>

                        default:      return "Ingen formulär laddades";
                        }
                    })()}
                </FormModal>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/saljmedoss.mp4" header="Vi säljer din bostad" buttontext="BOKA FRI VÄRDERING" buttonFunc={() => this.buttonClick(1)} ></Fullvid>

                <div id="content1">
                    <div className="text-center" >
                        <Textsection header="Maximalt betalt med Gbgfast som din mäklare och partner!" paragraph="Vi på Gbgfast har tillsammans över 70 års yrkeserfarenhet från runt tio olika varumärken. Med detta som grund skapades vårt varumärke till att erbjuda något nytänkande, exklusivt och modernt.">
                            <p>”EN BRILJANT IDÉ MED SIKTE PÅ EXKLUSIVITET<br></br>
                                OCH UNIKA TJÄNSTER UTANFÖR TRADITIONELLA RAMVERK”
                            </p>

                        </Textsection>
                        
                        <Textsection header="Ett klokt beslut!" paragraph="Vi på Gbgfast är inte rädda att gå utanför traditionella ramverk, genom att tillämpa modern teknik och metoder, för att få mest valuta för din bostad. Vill du veta hur vi gör? Kontakta oss!">
                            
                            <p className="  fs-5 text-start">Är du nyfiken på nya boende före de är på marknaden? Då är <b>Etta på bollen</b> en intressant och helt kostnadsfri bevakningstjänst!</p>

                            <p className="  fs-5 text-start">Vår rådgivningstjänst <b>Aktuell marknad</b> är en komplett miniutbildning vi levererar
                                kostnadsfritt som är baserad på aktuell marknadsinformation, låneregler,
                                trender och allt du behöver veta för att ta rätt beslut.</p>
                            <p className="  fs-5 text-start">Har du en exklusiv bostad eller behov att sälja utan att någon vet så listar vi den underhand med vår tjänst <b>Sälj i Smyg</b>. Matchningen sker löpande mot utvalda intressenter över

                                hela Europa.</p>
                            <p className="  fs-5 text-start ">För dig som vill ha koll på utveckling av hur din bostad förändras i värde över tid har vi

                                den praktiska tjänsten <b>Värde nu & sedan</b>. Tanken är att du som vill sälja i framtiden, eller kontakta banken för att lägga om lån, skall kunna uppnå absolut bäst timing.</p>

                        </Textsection>
                    </div>
                    <Textsection header="Ta del av vår kunskap och erfarenhet" paragraph=""></Textsection>
                    <Imagegrid3 buttonFunc={this.buttonClick.bind(this)}></Imagegrid3>
                        




                    

                </div>
                <div className="mt-5"></div>


                <Contentblock>

                    <Footer></Footer>
                </Contentblock>
            </div>

        )
    }
}