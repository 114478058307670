import React, { Component } from 'react';
import Modal from './modal';
export default class FormModal extends Component {
    constructor(props) { 
        super(props); 
        this.state = {
          modalVisible:false,
        }; 
    } 

    modalToggle () {
        this.setState({modalVisible: true})

    }
    modalToggleOff () {
        this.setState({modalVisible: false})

    }
    modalToggleFinish () {
        this.setState({modalVisible: false})

    }
    render() {
        

        return(
                
                <div>
                    <div className="modalFinish">

                    </div>
                    <div style={this.state.modalVisible ? {display:"block"} : {display:"none"} } onClick={this.modalToggleOff.bind(this)}><span className="close" style={{cursor:"pointer"}}></span></div>

                    <Modal modalVisible={this.state.modalVisible}>
                    
                    {React.cloneElement(this.props.children, { modalToggleFinish: this.modalToggleFinish.bind(this) })}
    
                    </Modal>
                </div>

        )
    }
}



