import React, { Component } from 'react'

export default class Textsection extends Component {

    render() {
        

        return(
        
        <div  className="container-sm mb-5" style={{marginTop:this.props.marginTop}}>


            <div className={`pill ${ this.props.textCont}`}>


            <h1 className={`pill ${ this.props.alignment }`}  style={{color:this.props.color}}>{this.props.header}</h1>
            <h2 className={`pill ${ this.props.alignment }`}  style={{color:this.props.color}}>{this.props.header2}</h2>
            <p className="  fs-5" style={{marginTop:"1rem", textAlign:this.props.alignment2,color:this.props.color}}>
            {this.props.paragraph}
            </p>


            {this.props.children}

            </div>



          </div>
        )
    }
}




Textsection.defaultProps = {
    header:"Endast det bästa är gott nog",
    header2:"",
    paragraph:"Tack vare vårt nytänkande i mix med erfarenhet från de största varumärkena, vårt aktiva köparregister och engagemang har våra mäklare stor kompetens. Med oss får du maximalt betalt för din bostad när du säljer med Gbgfast.",
    alignment:"text-center",
    alignment2:"left",
    marginTop:"4rem",
    slide:true,
    color: "",
    textCont: "textContainer",


  };