import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';
import Akademi from '../components/forms/akademi';
import SmoothText from '../components/smoothText';
export default class akademi extends Component {


    componentDidMount() {
        document.title = 'Akademi';
      }
    render() {
        return(
            <div>
                <Header></Header>

                <Fullvid height="100vh" img="/vids/akademi_utan_text_short.mp4" brightness="brightness(100%)" header="" buttontext=":)" button={false} text=""  >
                <SmoothText fontSize={"4rem"} wordList={["Hitta din inre säljare","Gör karriär", "utbilda dig","Forma din framtid"]}></SmoothText>

                </Fullvid>
                
                <div id="content1">
                <Contentblock>

                    <Textsection header="Akademi" paragraph="Söker du nya utmaningar? Akademin ger dig en ny möjlighet att kunna ta del av arbetslivet under tiden du studerar. Som en del av akademin får du följa mäklares vardag och kunna utvecklas som student 
Vi brinner så klart för vårt varumärke och nöjer oss inte med mindre än att driva utvecklingen i branschen.">
                    <div className="text-center fs-5  ">
                        <p>Välkommen med din förfrågan nedan.</p></div>
                    </Textsection>

                    <div id="form" className="container p-5" style={{ backgroundColor: "#fef3e4" }}>
                        <Akademi></Akademi>
              </div>
                </Contentblock>
                <Contentblock>
                    <Textsection header="Mäklarskolan" paragraph="">
                        <p  className="  fs-5">Din ansökan kommer behandlas via ett antal urvals-processer och analyser före beslut om intervju. Efter intervjun får du besked om du kvalificerat dig till vår testmiljö för kundkoordinator och backoffice. Detta gäller oavsett om du studerar till fastighetsmäklare eller vill kvalificera dig för en uppdragsutbildning.<br/></p>
                        <p className="  fs-5">Grattis, du har nu fått chansen att visa dina förmågor. Telekommunikation är den yttersta prövningen och fundamentet till i princip allt entreprenörskap. Den är också en av grundstenarna för att lyckas som mäklare. Vi kommer pröva din förmåga i en till max tre månader, ditt resultat är lönegrundande via provision.</p>
                        <p className="  fs-5"> Fantastiskt du är vidare i processen, i detta steg tas beslut om beställning av uppdragsutbildning eller så pluggar du redan. Backoffice och löpande arbete med kunder är grund-arkitekturen för din framtid som mäklare. Här skapar du framtida relationer och bygger ditt nätverk samtidigt som du pluggar. Du bör också vara mycket duktig och aktiv på sociala medier, allt i enlighet med vår policy för sociala medier. Som backoffice-resurs är du en del av teamet och samarbetar mycket nära våra befintliga mäklare. Här får du provision på antal bokade möten och även del av provision på den mäklare du genererat ett uppdrag för. Backoffice teamet får också plugghjälp och support av ledning och mäklare löpande under studietiden. Två dagar i veckan ägnas åt den här delen av din utbildning.</p>
                        <p className="  fs-5">Nu är du nära startskottet, du har ca 6-3 månader kvar före din examen och registrering till fastighetsmäklare, vår ambition är då att du skall vara en av våra nya stjärnor. Nu börjar den praktik som är obligatorisk för din registrering. Nu varvas din tid på backoffice med ren praktik på fältet och som du förväntas hantera som mäklare!</p>
                        <p className="  fs-5">Viktigt är att du bär upp vårt gemensamma varumärke med stolthet. När du är verksam såväl som privat är du alltid ett föredöme för dina kollegor och varumärke. Vi har också en rad policys att förhålla oss till så som regler för sociala medier, klädsel etcetera. Att vara ambassadör för ett varumärke förpliktigar kring allt man gör på fritid med vänner och i alla social situationer. 
Rätt hanterat skapar detta relationer du har nytta av hela din karriär.</p>
                    </Textsection>
                </Contentblock>

                </div>
    
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>
            </div>
        )
    }
}