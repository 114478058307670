import React, { Component} from 'react'
import { Select } from 'antd';
import Slide from 'react-reveal/Slide';

import Objekt from './objekt';
import Textsection from './textsection';
const { Option } = Select;






export default class Shop extends Component {


  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
      filtered:false,
      priceFiltered:false,

      filterList:[],
    }; 
    this.setRange = this.setRange.bind(this)
} 

  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }





setSearchQuery(query, data) {
  var searchQuery = query
  var results = []

  Object.keys(data).forEach((key, index) => {
    try {
      if(data[key]["location"]["streetAddress"].toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push(key)
      }
    } 
    catch(exception_var) {
      console.log("No streetadress defined")
    }
    try {
      if(data[key]["location"]["district"].toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push(key)
      }
    } 
    catch(exception_var) {
      console.log("No district defined")
    }
    try {
      if(data[key]["location"]["city"].toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push(key)
      }
    } 
    catch(exception_var) {
      console.log("No city defined")
    }

  })
   


  console.log(results)
  this.setState({filtered:true, filterList:results})

  

  
}



setRange(value) {
  var min = parseInt(value.split(",")[0])
  var max = parseInt(value.split(",")[1])

  this.setState({priceMin: min, priceMax:max, priceFiltered:true})
}


componentDidMount() {
  this.getData();
}


    render() {
        
      
        return(
            <div className="mt-4">
              <div>
 
              </div>
                <div className="container-sm filterW">
                    <div className="row  justify-content-center text-center ">
                    <div className="col p-0  px-1">
                                <input
                        value={this.state.searchQuery}
                        onInput={e => this.setSearchQuery(e.target.value, this.state.deals)}
                        type="text"
                        placeholder="Sök"
                        name="s"
                        id="searchbar"
                        style={{width:"100%"}}
                    />
                    
                    </div>
                    
                    <div className="col px-1">
                        <Select defaultValue="Prisklass" style={{ width: "100%" }} onChange={this.setRange}>

                            <Option value="0,999999">&#60; 1 000 000 kr</Option>

                            <Option value="1000000,1999999">1 000 000 - 2 000 000 kr</Option>
                            <Option value="2000000,2999999">2 000 000 - 3 000 000 kr</Option>
                            <Option value="3000000,3999999">3 000 000 - 4 000 000 kr</Option>
                            <Option value="4000000,4999999">4 000 000 - 5 000 000 kr</Option>
                            <Option value="5000000,7499999">5 000 000 - 7 500 000 kr</Option>
                            <Option value="7500000,9999999">7 500 000  - 10 000 000 kr</Option>
                            <Option value="10000000,10000000000000"> &#62; 10 000 000 kr</Option>
                            





                        </Select>
                    </div>
                    
                    </div>


          </div>
  
            <Slide bottom cascade>

            <div className="fastighetgrid">
            {
              this.state.deals &&
              Object.keys(this.state.deals).map((key,i) => {
              if(this.state.deals[key]["publishingInformation"]["isPreview"] === false && (this.state.deals[key]["publishingInformation"]["saleStatus"] === "ENUMS_SALE_STATUS_FOR_SALE" || this.state.deals[key]["publishingInformation"]["saleStatus"] === "ENUMS_SALE_STATUS_COMING") && (this.state.filterList.includes(key) || !this.state.filtered) && 
              ((this.state.deals[key]["saleInformation"]["price"] > this.state.priceMin && this.state.deals[key]["saleInformation"]["price"] < this.state.priceMax) || !this.state.priceFiltered )  ) {
                return <Objekt key={i} data={this.state.deals[key]}></Objekt>

              }
              return null
              
            }).reverse()
               }

               
          </div>


          <Textsection header="Sålda objekt" paragraph=""></Textsection>
          <div className="fastighetgrid">
          {
              this.state.deals &&
              Object.keys(this.state.deals).map((key,i) => {
              if(this.state.deals[key]["publishingInformation"]["saleStatus"] === "ENUMS_SALE_STATUS_SOLD") {
                return <Objekt key={i} isSold={true} data={this.state.deals[key]}></Objekt>

              }
              return null
 
              
            }).reverse()
               }
          
          
          </div>
          </Slide>
          </div>
        )
    }
}




