import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';

import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';

import Footer from '../components/footer';
import Contact from '../components/forms/contact';
export default class michael extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
    };
     
} 
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }

componentDidMount() {
  document.title = 'Michael';

  this.getData();
  
  
}
getNestedObject = (nestedObj, pathArr) => {


  return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    

}
eval2(data) {
  data = this.getNestedObject(this.state.deals, data)

  if(data !== undefined) {
    data = data.toLocaleString()

  }
  else {
    data = undefined
  }
  return data
}
    render() {
        return(
            <div>
            <Header></Header>
            <Fullvid height="80vh" brightness="brightness(50%)" img="./img/Jungsen färdig.png" displayImg={true} header="Michael Jungsén" button={false} text="REG. FASTIGHETSMÄKLARE" ></Fullvid>
            <Contentblock>                
                <Textsection header="VEM ÄR MICHAEL?" paragraph="Jag är glad, energisk och positiv med full fart framåt! Då jag älskar fastigheter i alla dess former tar jag mig an varje kund med stort engagemang oavsett om förmedlingsuppdraget gäller privatbostäder, nyproduktion eller transaktion av kommersiella fastigheter. Med min omfattande erfarenhet, kunskaper och kontaktnät kan jag garanterat hitta rätt kund till just ert behov."></Textsection>
                <Contentblock>

                </Contentblock>
                <div id="form" className="container p-5" style={{ backgroundColor: "#fef3e4" }}>
                <Contact agentId="MDAtMXwwMDAwMDAwOTg3OXw0" agent={" Michael?"}></Contact>

              </div>     
              <Textsection header="Ett urval av tidigare försäljningar" paragraph=""></Textsection>
                {
      this.state.deals &&
      Object.keys(this.state.deals).map((key,i) => {
      console.log(this.state.deals[key])
      if(this.state.deals[key]["publishingInformation"]["isPreview"] === false && this.state.deals[key]["publishingInformation"]["saleStatus"] === "ENUMS_SALE_STATUS_SOLD" && this.state.deals[key]["users"].filter(user => (user.id === "MDAtMXwwMDAwMDAwOTg3OXw0")).length > 0) {
       
        return (
        <div className="fastighet">
            <div className="fastighetimg">
            <img alt="houseimg" src={"/deals/img/" + this.state.deals[key]["id"].replace("..", "") + "/main.jpg"} />

            </div>
            <div className="fastighetinfo">
            <p >{this.eval2([key,"location","city"]) + " / " + this.eval2([key,"location","district"])}</p>
            <h2 >{this.eval2([key,"location","streetAddress"])}</h2>
            <p className="fs-4">Pris: {this.eval2([key,"saleInformation","startingPrice"])} kr</p>

          </div>

          </div>

        )

      }
      return null
      
    })
       }

            </Contentblock>



            <Contentblock>
                <Footer></Footer>
            </Contentblock>
        </div>

    )
}
}