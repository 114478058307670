import React, { Component } from 'react'

import Header from "../components/header";
import Fullvid from '../components/Fullvid';
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';
import Footer from '../components/footer';

export default class historia extends Component {
    componentDidMount() {
        document.title = 'Historia';
      }
    render() {
        return(
            <div>
                <Header></Header>
                <Fullvid height="80vh" img="/vids/historia.mp4" header="Gbgfast" buttontext=":)" button={false} text="HUR KONSTIG ÄR HISTORIEN" ></Fullvid>
                
                <div id="content1">
                <Contentblock>
 
                    <Textsection header="Livet är en strävan mot det bättre" paragraph="Vi på GBG FAST har tillsammans 70 års yrkeserfarenhet från runt 10 olika varumärken. Med detta som grund skapades vårt varumärke och kärleken till att erbjuda något exklusivt och modernt för våra kunder. ">
                    <div className="text-center fs-5  ">
                        <p>”EN BRILJANT IDÉ MED SIKTE PÅ EXKLUSIVITET
 OCH UNIKA TJÄNSTER UTANFÖR TRADITIONELLA RAMVERK”</p></div>
                    </Textsection>


                </Contentblock>
                <Contentblock>
                    <Textsection header="Vår idé" paragraph="GBG FAST är grundat och utvecklat av samtliga medarbetare med olika kompetenser från olika varumärken. Majoriteten av Sveriges mäklarföretag levererar i princip samma tjänsteutbud, här har vi istället skapat unika produkter. ">
                        <p  className="  fs-5">Nu 2021 öppnar vi dörrarna i Göteborg inom Nyproduktion – Vanligt mäkleri – Kommersiell förmedling och andra unika tilläggstjänster.</p>
                        <p className="  fs-5">GBG FAST har en ambition att bli en av de ledande mäklarbyråerna, med internationella förgreningar och exklusiva objekt som målfokus.</p>
                    </Textsection>
                </Contentblock>
                    <div className="container" style={{ filter:"grayscale(80%)"}} >
                        <div className="row gy-4 gx-4">

                   
                            
                            </div>
                            <div className="row gy-4 gx-4 mt-1">



                            <div className='text-center'>
                                <img alt="bild på mäklare" src="./img/leifprest.jpg" ></img>
                            </div>

    

                            </div>

                            

                    </div>
                </div>
                <Contentblock>
                    <Textsection alignment="text-start fs-4" header="Varför skapa ett helt nytt och unikt varumärke?
" paragraph="LEIF: Främst frustrationen att förändringsarbeten tar år i de traditionella kedjorna oavsett hur briljanta idéer du har. Dessutom den irriterande vetskapen om att kedjorna tar ca 10% av mäklarnas omsättning i eget vinstsyfte. Nu har vi en mycket större möjlighet att lägga detta på unik och skräddarsydd marknadsföring för våra säljare i ambitionen att få absolut bäst betalt. Sist men inte minst så har branschen i princip samma kanaler och tilläggstjänster för marknadsföring och exponering, här kommer vi sticka ut och kanske bli det enda valbara alternativet."></Textsection>
                
                <Textsection alignment="text-start fs-4" header="Vad är vi mest stolta över?" 
                paragraph="Att få vara med från början att bygga nytt varumärke och skapa verktyg som är så extremt dynamiska att du kan förändra en mäklartjänst på ett dygn är oerhört inspirerande mot vad jag tidigare upplevt. Framför allt skapar det en extrem kundnytta."></Textsection>
                <Textsection alignment="text-start fs-4" header="Ni har brutit ny mark genom att ni jobbat med nyproduktion på ett helt nytt sätt – varför?" 
                paragraph="Fastighetsutvecklarna och byggherrarna i Sverige har mer och mer förstått sig på kvalitet och långsiktighet från de mäklare de engagerar. Att som mäklare jobba med nyproduktion ser väldigt annorlunda ut idag jämför med för bara 6-7 år sedan. Bland annat har vi lyckats skapa en kundbearbetning och marknadsföring som ingen annan kan leverera just nu."></Textsection>
                <Textsection alignment="text-start fs-4" header="Varför tror ni att ni har lyckats så bra?
" 
                paragraph="Vi sitter på den bästa tekniken för att filtrera och bearbeta kunder, de attraktivaste kontakterna för distinkt kundnytta sist men inte minst älskar vi att gå till jobbet och vara tillgängliga varje dag. Engagemang dygnet runt skapar affärer och kundnytta."></Textsection>
                
                </Contentblock>
                <Contentblock>
                    <Footer></Footer>
                </Contentblock>
            </div>
        )
    }
}